import { useState } from 'react';
import { AxiosError } from 'axios'

/* Generic hook storing API call states that can be used in JSX elements */

type ApiCall<T> = {
  data: T | null;                               // data returned by the request on success
  error: AxiosError | null;                     // error returned by the request on failure 
  isError: boolean;                             // true or false, the request caused an error
  isLoading: boolean;                           // true or false, the request is loading
  request: (...args: any[]) => Promise<void>;   // the request with its possible arguments
};

const useApi = <T>(apiFunc: (...args: any[]) => Promise<{ data: T }>): ApiCall<T> => {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const request = async (...args: any[]) => {
    setIsLoading(true);
    try {
      const result = await apiFunc(...args);
      setData(result.data);
    } catch (err: any) {
      setIsError(true);
      setError(err as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    error,
    isError,
    isLoading,
    request
  };
};

export default useApi;

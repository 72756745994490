import { useState, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Avatar, avatarKeys } from '../../components/Avatar';
import useAuth from '../../hooks/useAuth';
import { usernameReg, passwordReg } from '../../utils/regex'
import { createUser, EmailAlreadyExists, UsernameAlreadyExists, getCountries, uploadImage } from '../../app/api';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../utils/Toast';
import Spinner from '../../components/Spinner';
import { Country, CustomImage } from '../../app/models';
import { useTranslation } from 'react-i18next';



export function Signup() {

    const { t } = useTranslation();

    // Form validation schema
    const signupSchema = z
    .object({
        email: z.string().trim().min(1).max(256).email(),
        avatarKey: z.enum(avatarKeys as [string, ...string[]]),
        username: z.string().trim().min(1).max(256).regex(usernameReg, {
            message: t('Signup.FormUsernameCheck')
        }),
        password: z.string().min(8).max(256).regex(passwordReg, {
            message: t('Signup.FormPasswordCheck')
        }),
        confirm: z.string().min(8).max(256),
        country: z.string().min(1).max(256),
    })
    .refine((data) => data.password === data.confirm, {
        message: t('Signup.FormConfirmCheck'),
        path: ['confirm'],
    });

    // TS type created from form validation schema    
    type SignupValues = z.infer<typeof signupSchema>;

    const { setAuth } = useAuth(); // set session informations (visible in the localstorage)

    const [selectedAvatarKey, setSelectedAvatarKey] = useState<string>(avatarKeys[0])

    // Form values and states 
    const { register, handleSubmit, setValue, trigger, formState: { errors } } = useForm<SignupValues>({
        mode: "onBlur",
        resolver: zodResolver(signupSchema),
    });


    // set the form's "avatarKey" value when one of the avatar buttons is clicked
    const handleAvatarClick = (key: string) => {
        setValue("avatarKey", key)
        trigger("avatarKey")
    }
    
    const navigate = useNavigate();

    const [countries, setCountries] = useState<Country[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [image, setImage] = useState("")
    const [imageFile, setImageFile] = useState<File | null>(null)

    // Fetch countries list
    useEffect(() => {
        const fetchCountries = async () => {
            const countries = await getCountries();
            setCountries(countries);
            setLoading(false);
            setSelectedAvatarKey(selectedAvatarKey);
            handleAvatarClick(selectedAvatarKey);
        }
        fetchCountries();
    }, []);

    const onSignup = async (data: SignupValues) => {
        try{
            var response = await createUser({
                email: data.email,
                username: data.username,
                avatarKey: data.avatarKey,
                password: data.password,
                country: data.country
            });

            if(data.avatarKey==="custom" && imageFile && response){
                const temp : any = response;
                let base64Img : any = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(imageFile);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
                while((base64Img.length * (3/4)) - (base64Img.split('=')[1]?.length || 0) > 100000){
                    // reduce the quality of the image until it is under 100kb
                    const quality = parseFloat((100 * 100000 / (base64Img.length * (3/4))).toFixed(2));
                    base64Img = await new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = base64Img;
                        img.onload = () => {
                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');
                            if(!ctx) reject('No context');
                            canvas.width = img.width;
                            canvas.height = img.height;
                            ctx?.drawImage(img, 0, 0);
                            canvas.toBlob((blob: any) => {
                                if(!blob) reject('No blob');
                                const file = new File([blob], 'image', {type: 'image/jpeg', lastModified: Date.now()});
                                const reader = new FileReader();
                                reader.readAsDataURL(file);
                                reader.onload = () => resolve(reader.result);
                                reader.onerror = error => reject(error);
                            }, 'image/jpeg', quality/100);
                        };
                        img.onerror = error => reject(error);
                    });
                }
                const responseImage = await uploadImage({
                    _id: "",
                    image: base64Img,
                    fk_user_id: temp.id
                });
            }


            // redirect to login page
            showToast(t('Signup.SignupSuccess'), 'success');
            navigate('/login');
        }catch (error : any) {
            if (error instanceof UsernameAlreadyExists) {
                showToast(t('Signup.SignupFailUser'), 'error');
            } else if (error instanceof EmailAlreadyExists) {
                showToast(t('Signup.SignupFailEmail'), 'error');
            } else {
                console.log(error);
            }
        }
    }

    const selectImage = async () => {
        // Create a 'hidden' input element
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/png, image/jpeg'; // specify accepted file types
        input.onchange = (event) => {
            if(!event.target) return;
            const target = event.target as HTMLInputElement;
            if(!target.files) return;
            const file = target.files[0];
            // handle the file here
            setImageFile(file);
            setImage(URL.createObjectURL(file));
        };
        // Trigger the file dialog
        input.click();
    }

    return (
        <>
            {loading ? (
                <Spinner /> // render Spinner while loading
            ) : (
                <div className="flex min-h-full flex-col justify-center px-6 pt-0 py-12 lg:px-8">
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <h2 className="mt-10 text-center text-3xl  font-medium leading-6 tracking-tight text-gray-900 md:mb-5">
                            {t('Signup.Title')}
                        </h2>
                    </div>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm md:max-w-3xl">
                        <form onSubmit={handleSubmit(onSignup)}>

                            <div className="flex flex-col justify-center gap-8 md:flex-row md:gap-16">
                                <div className="space-y-6 sm:w-full">
                                    <div>
                                        <label htmlFor="email" className="block text-base font-medium leading-6 text-gray-900">
                                        {t('Signup.FormEmail')}
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="email"
                                                type="email"
                                                required
                                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                                {...register('email')}
                                            />
                                        </div>
                                        <div className="mt-1 text-xs text-red-500" role="alert">
                                            {errors.email?.message}
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="username" className="block text-base font-medium leading-6 text-gray-900">
                                            {t('Signup.FormUsername')}
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="username"
                                                type="text"
                                                autoComplete="given-username"
                                                required
                                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                                {...register('username')}
                                            />
                                        </div>
                                        {errors.username ? (
                                            <div className="mt-1 text-xs text-red-500" role="alert">
                                                {errors.username.message}
                                            </div>
                                        ) :
                                            <div className="mt-1 text-xs text-neutral-500">
                                                {t('Signup.FormUsernameCheck')}
                                            </div>
                                        }
                                    </div>
                                    <div>
                                        <label htmlFor="password" className="block text-base font-medium leading-6 text-gray-900">
                                            {t('Signup.FormPassword')}
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                                {...register('password')}
                                            />
                                        </div>
                                        {errors.password ? (
                                            <div className="mt-1 text-xs text-red-500" role="alert">
                                                {errors.password.message}
                                            </div>
                                        ) :
                                            <div className="mt-1 text-xs text-neutral-500">
                                                {t('Signup.FormPasswordCheck')}
                                            </div>
                                        }
                                    </div>
                                    <div>
                                        <label htmlFor="confirm" className="block text-base font-medium leading-6 text-gray-900">
                                            {t('Signup.FormPasswordConfirm')}
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="confirm"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                                {...register('confirm')}
                                            />
                                        </div>
                                        <div className="mt-1 text-xs text-red-500" role="alert">
                                            {errors.confirm?.message}
                                        </div>
                                    </div>
                                    <div>
                                        <label htmlFor="country" className="block text-base font-medium leading-6 text-gray-900">
                                            {t('Signup.FormCountry')}
                                        </label>
                                        <div className="mt-2">
                                            <select
                                                id="country"
                                                required
                                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                                {...register('country')}
                                            >
                                                {countries.map((country, index) => (
                                                    <option key={index} value={country.id}>
                                                        {country.country}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="sm:w-full md:max-w-xs">
                                    <p className="block text-base font-medium leading-6 text-gray-900">
                                        {t('Signup.FormAvatar')}
                                    </p>
                                    <div className="grid grid-cols-5 gap-4 place-items-center mt-2 p-6 bg-white rounded-md shadow-md sm:grid-cols-4 sm:p-8 mb-10">
                                        {avatarKeys.map((item) => {
                                            if (item === 'custom') {
                                                return null; 
                                            }

                                            return (
                                                <button
                                                    type="button"
                                                    key={item}
                                                    onClick={() => {
                                                        setSelectedAvatarKey(item);
                                                        handleAvatarClick(item);
                                                    }}
                                                    className={clsx(
                                                        item === selectedAvatarKey
                                                            ? 'border-4 border-primary-400'
                                                            : 'border border-gray-200 hover:border-4 hover:border-primary-100',
                                                        'w-12 h-12 flex justify-center items-center rounded-md'
                                                    )}
                                                >
                                                    <Avatar avatarKey={item} className="w-7 h-7" />
                                                </button>
                                        )})}
                                        {image === "" ? (null) : (
                                            <button
                                                type="button"
                                                onClick={() => {
                                                    setSelectedAvatarKey("custom");
                                                    handleAvatarClick("custom");
                                                }}
                                                className={clsx(
                                                    "custom" === selectedAvatarKey
                                                        ? 'border-4 border-primary-400'
                                                        : 'border border-gray-200 hover:border-4 hover:border-primary-100',
                                                    'w-12 h-12 flex justify-center items-center rounded-md'
                                                )}
                                            >
                                                <img alt="preview profile picture" className="object-cover w-full h-full" src={image}/>
                                            </button>
                                        )}
                                    </div>
                                    <div className='flex flex-col justify-center gap-8 md:flex-row md:gap-16'>
                                         <button
                                            type="button"
                                            onClick={() => {
                                                selectImage();
                                                setSelectedAvatarKey("custom");
                                                handleAvatarClick("custom");
                                            }}
                                            className="flex w-full justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
                                        >
                                            {t('Signup.FormChooseImage')}
                                        </button>
                                        
                                        
                                    </div>
                                </div>
                            </div>

                            <div className="mt-12">
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
                                >
                                    {t('Signup.FormSignup')}
                                </button>
                            </div>

                        </form>

                    </div >


                </div >
            )}
        </>
    )
}
import type { RouteObject } from 'react-router-dom';
import { PublicContent } from './PublicContent';
import { Login } from '../pages/public/Login' 
import { Signup } from '../pages/public/Signup' 
import { Logout } from '../pages/public/Logout' 
import { ForgotPass } from '../pages/public/ForgotPass'
import { ResetPass } from '../pages/public/ResetPass'

// routes accessible to the public role (unauthenticated)
// child routes below will be returned after checking that no session exists via PublicContent element
export const publicRoutes: RouteObject[] = [
  {
    element: <PublicContent />,
    children: [
      {
        path: '/login',
        element: <Login />,
      },
      {
        path: '/signup',
        element: <Signup />,
      },
      {
        path: '/logout',
        element: <Logout />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPass />,
      },
      {
        path: '/reset-password/:email/:token',
        element: <ResetPass />,
      },
    ],
  },
];
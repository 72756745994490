import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { PiUserCircle } from 'react-icons/pi'
import clsx from 'clsx';
import { Session } from "../app/models"
import useAuth from "../hooks/useAuth";
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { updateTimeSpent } from '../app/api';

interface NavigationItem {
    name: string;
    to: string;
}








export function Navbar() {

    const { t } = useTranslation();

    const { auth, deleteAuth } = useAuth();  // auth: session informations (visible in the localstorage)

    const location = useLocation();

    const navigate = useNavigate();

    const userNavigation: NavigationItem[] = [
        {
            name: t('Nav.HomeTitle'),
            to: '/',
        },
        {
            name: t('Nav.GeneralDisc'),
            to: '/general-discussions',
        },
        {
            name: t('Nav.PersonalDisc'),
            to: '/me-and-my-mood'
        }
    ];
    
    const adminNavigation: NavigationItem[] = [
        {
            name: t('Nav.HomeTitle'),
            to: '/',
        },
        {
            name: t('Nav.GeneralDisc'),
            to: '/general-discussions',
        },
        {
            name: t('Nav.Stats'),
            to: '/stats',
        }
    ];
    
    const publicNavigation: NavigationItem[] = [
        {
            name: t('Nav.Connexion'),
            to: '/login',
        },
        {
            name: t('Nav.Signup'),
            to: '/signup',
        },
    ];

    // If there's no active session, return publicNavigation otherwise userNavigation
    function getNavigationItems(auth?: Session): NavigationItem[] {
        if (!auth) {
            return publicNavigation;
        } else if(auth.role === "admin") {
            return adminNavigation;
        }else{
            return userNavigation;
        
        }
    }

    const navigation = getNavigationItems(auth); // return userNavigation or publicNavigation


    const onLogout = () => {
        let timespent = localStorage.getItem('timespent');
        if(timespent!==""){
            localStorage.setItem('timespent', "");
            let time = new Date().getTime() - parseInt(timespent!);
            time = Math.floor(time / 60000);
            // send to backend
            if(auth){
                updateTimeSpent(auth?.user._id, time);
            }
        }else{
            console.log("maybe problem with the time spent")
        }


        deleteAuth();
        navigate('/logout', { replace: true });
    };

    const changeLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang);
    };

    


    return (
        <Disclosure as="nav" className="bg-primary-500">
            {({ open }) => (
                <>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-24 items-center justify-between">
                            <div className="flex items-center">
                                <Link
                                    to={navigation[0].to}
                                    key={navigation[0].to}
                                >
                                    <span className="text-3xl text-white">Forum Indo-Suisse</span>
                                </Link>
                            </div>
                            <div className="hidden lg:block">
                                <div className="ml-10 flex items-center space-x-4">

                                    {/* Navigation links */}
                                    {navigation.map((item) => (
                                        <Link
                                            to={item.to}
                                            key={item.to}
                                            className={clsx(
                                                item.to === location.pathname
                                                    ? 'bg-primary-800 text-white'
                                                    : 'bg-primary-500 hover:bg-primary-400 text-white',
                                                'rounded-md px-3 py-2 text-lg'
                                            )}
                                            aria-current={
                                                item.to === location.pathname ? 'page' : undefined
                                            }
                                        >
                                            {item.name}
                                        </Link>
                                    ))}

                                    {/* Profile dropdown */}
                                    {auth && (
                                        <Menu as="div" className="relative ml-3">
                                            <div>
                                                <Menu.Button className="flex rounded-full p-1 bg-primary-500 text-sm text-gray-100 hover:text-white hover:bg-primary-400">
                                                    <PiUserCircle className="h-11 w-11" />
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                    <div className="py-1 border-b border-slate-900/5">
                                                        <Menu.Item disabled>
                                                            <div className="block px-4 py-2 text-left">
                                                                <p className="text-base font-medium text-gray-900">
                                                                    {auth?.user.username}
                                                                </p>
                                                                <p className="mt-0.5 text-left text-xs text-gray-700">
                                                                    {auth?.user.email}
                                                                </p>
                                                            </div>
                                                        </Menu.Item>
                                                    </div>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <Link
                                                                to={`/account`}
                                                                className={clsx(
                                                                    active ? 'bg-gray-100' : '',
                                                                    'block w-full px-4 py-2 text-left text-sm text-gray-700'
                                                                )}
                                                            >
                                                                {t('Nav.NavMyInfo')}
                                                            </Link>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                type="button"
                                                                onClick={onLogout}
                                                                className={clsx(
                                                                    active ? 'bg-gray-100' : '',
                                                                    'block w-full px-4 py-2 text-left text-sm text-gray-700'
                                                                )}
                                                            >
                                                                {t('Nav.NavLogout')}
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </Menu.Items>
                                            </Transition>
                                        </Menu>
                                    )}

                                    {/* Language selection */}
                                    <Menu as="div" className="relative ml-3">
                                        <div>
                                            <Menu.Button className="flex rounded-full p-1 bg-primary-500 text-sm text-gray-100 hover:text-white hover:bg-primary-400">
                                                {i18n.language === 'fr' ? 'FR' : 'EN'}
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            type="button"
                                                            onClick={() => changeLanguage('fr')}
                                                            className={clsx(
                                                                active ? 'bg-gray-100' : '',
                                                                'block w-full px-4 py-2 text-left text-sm text-gray-700'
                                                            )}
                                                        >
                                                            Français
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <button
                                                            type="button"
                                                            onClick={() => changeLanguage('en')}
                                                            className={clsx(
                                                                active ? 'bg-gray-100' : '',
                                                                'block w-full px-4 py-2 text-left text-sm text-gray-700'
                                                            )}
                                                        >
                                                            English
                                                        </button>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                            </div>
                            <div className="-mr-2 flex lg:hidden">

                                {/* Mobile menu button */}
                                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-primary-400">
                                    <span className="sr-only">{t('Nav.NavOpenMenu')}</span>
                                    {open ? (
                                        <XMarkIcon className="block h-9 w-9" aria-hidden="true" />
                                    ) : (
                                        <Bars3Icon className="block h-9 w-9" aria-hidden="true" />
                                    )}
                                </Disclosure.Button>
                            </div>
                        </div>
                    </div>
                    <Disclosure.Panel className="lg:hidden">
                        <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">

                            {/* Mobile menu navigation links */}
                            {navigation.map((item) => (
                                <Disclosure.Button
                                    as={Link}
                                    to={item.to}
                                    key={item.to}
                                    className={clsx(
                                        item.to === location.pathname
                                            ? 'bg-gray-900 text-white'
                                            : 'bg-primary-500 hover:bg-primary-400 text-white',
                                        'block rounded-md px-3 py-2 text-lg'
                                    )}
                                    aria-current={
                                        item.to === location.pathname ? 'page' : undefined
                                    }
                                >
                                    {item.name}
                                </Disclosure.Button>
                            ))}
                        </div>

                        <Menu as="div" className="relative ml-3">
                            <div>
                                <Menu.Button className="flex rounded-full p-1 bg-primary-500 text-sm text-gray-100 hover:text-white hover:bg-primary-400">
                                    {i18n.language === 'fr' ? 'FR' : 'EN'}
                                </Menu.Button>
                            </div>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                type="button"
                                                onClick={() => changeLanguage('fr')}
                                                className={clsx(
                                                    active ? 'bg-gray-100' : '',
                                                    'block w-full px-4 py-2 text-left text-sm text-gray-700'
                                                )}
                                            >
                                                Français
                                            </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <button
                                                type="button"
                                                onClick={() => changeLanguage('en')}
                                                className={clsx(
                                                    active ? 'bg-gray-100' : '',
                                                    'block w-full px-4 py-2 text-left text-sm text-gray-700'
                                                )}
                                            >
                                                English
                                            </button>
                                        )}
                                    </Menu.Item>
                                </Menu.Items>
                            </Transition>
                        </Menu>

                        {/* Mobile menu user profile */}
                        {auth && (
                            <div className="border-t border-primary-600 pb-3 pt-4">
                                <div className="flex items-center px-5">
                                    <div className="bg-primary-500 text-sm text-gray-100">
                                        <PiUserCircle className="h-10 w-10 rounded-full" />
                                    </div>
                                    <div className="ml-3">
                                        <div className="text-base font-medium leading-none text-white">{auth?.user.username}</div>
                                        <div className="text-sm font-medium leading-none text-gray-400">{auth?.user.email}</div>
                                    </div>
                                </div>
                                <div className="mt-3 space-y-1 px-2">
                                    <Disclosure.Button
                                        as={Link}
                                        to={`/account`}
                                        className="block rounded-md px-3 py-2 text-base font-medium bg-primary-500 hover:bg-primary-400 text-white"
                                    >
                                        {t('Nav.NavMyInfo')}
                                    </Disclosure.Button>
                                    <Disclosure.Button
                                        as="button"
                                        onClick={onLogout}
                                        className="block w-full text-left rounded-md px-3 py-2 text-base font-medium bg-primary-500 hover:bg-primary-400 text-white"
                                    >
                                        {t('Nav.NavLogout')}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        )}
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure >
    );
}
import { useState, useEffect } from "react";
import { zodResolver } from '@hookform/resolvers/zod';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { GoPlusCircle } from "react-icons/go"
import { ModuleById } from "../app/models";
import FormModal from "../components/modals/FormModal";
import { NewDiscussionForm } from "../components/NewDiscussionForm";
import { DiscussionCard } from "../components/Card"

import useAuth from "../hooks/useAuth";
import { createDiscussion, getModule, getPersonalDiscussion, getSubgroups } from "../app/api";
import { showToast } from "../utils/Toast";
import { ErrorLayout } from "../layouts/ErrorLayout";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


// Form validation schema
const schema = z.object({
  title: z.string().trim().min(1).max(256),
  firstMessage: z.string().trim().min(1).max(256),
})


// TS type created from form validation schema
export type SchemaValues = z.infer<typeof schema>;


export function DiscussionListPage() {

  const { t } = useTranslation();

  const location = useLocation();

  const [isNewDiscussionModalOpen, setNewDiscussionModalOpen] = useState(false); // open or close the discussion creation modal

  const [isPersonalPage, setIsPersonalPage] = useState(false); // personal or general discussions page

  const [module, setModule] = useState<ModuleById>(); // list of modules

  const [subgroups, setSubgroups] = useState([]); // Subgroups list

  const navigate = useNavigate();

  // Form values and states 
  const { register, handleSubmit, formState: { errors } } = useForm<SchemaValues>({
    resolver: zodResolver(schema),
  });

  const fetchData = async (isPersonal: boolean) => {
    const userId : any = auth?.user._id;
    if(isPersonal){
      const personalDisc = await getPersonalDiscussion(userId);
      setModule(personalDisc.data);
    }else{
      let subgroupId = auth?.user.fk_subgroup_id;
      let country = auth?.user.fk_country_id;
      if(auth?.role === 'admin'){
        subgroupId = 1;
      }
      if(!subgroupId || !country) {
        showToast(t('DiscussionListPage.GetDiscError'), 'error');
        return <ErrorLayout />;
      }
      const generalDisc = await getModule("placeholder", subgroupId, country, true);
      setModule(generalDisc.data);
    }

    const subgroupResponse = await getSubgroups();
    setSubgroups(subgroupResponse.data);

  };

  useEffect(() => {
    const pathname = location.pathname;
    const segment = pathname.substring(1);
    const isPersonal = segment === "me-and-my-mood";
    setIsPersonalPage(isPersonal);
    fetchData(isPersonal);
  }, [location]);

  

  const onCreate = async (data: SchemaValues) => {
    if(!module){
      showToast(t('DiscussionListPage.CreateDiscError'), 'error');
      return <ErrorLayout />;
    }else {
      const userId = auth?.user._id;
      const subgroupNumber = auth?.user.fk_subgroup_id;
      if(!userId || !subgroupNumber){
        showToast(t('DiscussionListPage.CreateDiscError'), 'error');
        return <ErrorLayout />;
      }
      const response = await createDiscussion(data.title, data.firstMessage, module?.id.toString(), userId, isPersonalPage, subgroupNumber);
      setNewDiscussionModalOpen(false);
      showToast(t('DiscussionListPage.CreateDiscSuccess'), 'success');
      navigate('/discussions/'+response.data._id);
    }
  }

  const changeSubgroup = async (subgroupNumber: any) => {
    let country = auth?.user.fk_country_id;
    if(!country) {
      showToast(t('DiscussionListPage.GetDiscError'), 'error');
      return <ErrorLayout />;
    }
    const generalDisc = await getModule("placeholder", subgroupNumber, country, true);
    setModule(generalDisc.data);
  }
  
  const { auth } = useAuth();


  return ( /* TODO: use getModule API call data in rendering */
    <>
      {module && (
        <>
          <section className="mb-12 sm:mb-18 md:mb-20">
            <div className='p-6 border-2 bg-white border-secondaryRed-500 rounded-lg shadow-lg'>
              <div className="flex space-x-4 items-center mb-2 sm:mb-4">
                <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">{t('DiscussionListPage.ModuleTitle')}</h3>
                <span className='h-8 w-px bg-secondaryRed-200'></span>
                <p className="text-xl font-medium text-secondaryRed-700">{t('Modules.'+module.name+'.Type')}</p>
              </div>
              <p className="mb-1 font-medium text-gray-900">{t('DiscussionListPage.ModuleDescription')}</p>
              <p className='text-base text-gray-500'>{t('Modules.'+module.name+'.Description')}</p>
            </div>
          </section> 

          <section>
            <div className="flex flex-col mb-12 space-y-4 md:flex-row md:space-y-0">
              <div className="flex space-x-4 items-center">
                <h3 className="text-xl font-medium text-gray-900 sm:text-2xl">{t('DiscussionListPage.DiscLinkedToModule')}</h3>
              </div>
              {auth?.role === 'admin' ? (
                <select onChange={(e) => changeSubgroup(e.target.value)}
                  className="block bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 md:ml-auto"
                >
                  {subgroups.map((item : any) => (
                    <option key={item.id} value={item.number}>{t('DiscussionListPage.Group')}{item.number}</option>
                  ))}
                </select>
              ) : (
                <button
                  type="button"
                  onClick={() => { setNewDiscussionModalOpen(true) }}
                  className="flex gap-1.5 items-center font-bold text-secondaryGreen-400  hover:text-secondaryGreen-600 md:ml-auto"
                >
                  <GoPlusCircle className="w-7 h-7" /> {t('DiscussionListPage.CreateDisc')}
                </button>
                )}
            </div>
            <div className="flex flex-col space-y-8">
              {module.discussions.map((item) => (
                <DiscussionCard
                  key={item._id}
                  to={item._id}
                  title={item.title}
                  description={item.description[item.description.length - 1]}
                  isDeleted={item.deleted}
                  isAdmin={auth?.role === 'admin'}
                />
              ))}
            </div>
          </section>
        </>
      )}

      <FormModal
        isOpen={isNewDiscussionModalOpen}
        title={t('DiscussionListPage.FormNewDisc')}
        content={<NewDiscussionForm register={register} errors={errors} />}
        cancel={() => { setNewDiscussionModalOpen(false) }}
        cancelLabel={t('DiscussionListPage.Cancel')}
        confirm={handleSubmit(onCreate)}
        confirmLabel={t('DiscussionListPage.Create')}
      />
    </>
  )
}
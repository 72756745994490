import { PiMotorcycleFill, PiFilmStrip, PiMusicNotesFill, PiMedal, PiPlantLight } from 'react-icons/pi'
import { IoMdFootball, IoMdFlower } from 'react-icons/io'
import { MdRestaurant, MdSportsEsports } from 'react-icons/md'
import { TbChefHat, TbCup } from 'react-icons/tb'

import { GiTurtle  } from "react-icons/gi";
import { FaCat, FaDog } from "react-icons/fa";
import { LuBird } from "react-icons/lu";
import { BsEmojiWink, BsEmojiHeartEyes, BsEmojiSmile, BsEmojiSunglasses } from "react-icons/bs";
import { GoAlert } from "react-icons/go";

/* Component used by the signup and account page*/

// To add an avatar:
// 1. import above an icon component from the "react-icons" library (https://react-icons.github.io/react-icons/)
// 2. add component to avatarsList below with a new keyword

interface Avatars {
    [keyword: string]: { iconType: React.ElementType };
}

// Contains a list of avatars that can be used in the app
// using the keyword assigned to them
const avatarsList: Avatars = {
    moto: { iconType: PiMotorcycleFill },
    film: { iconType: PiFilmStrip },
    music: { iconType: PiMusicNotesFill },
    medal: { iconType: PiMedal },
    football: { iconType: IoMdFootball },
    restaurant: { iconType: MdRestaurant },
    videogame: { iconType: MdSportsEsports },
    chefhat: { iconType: TbChefHat },
    cup: { iconType: TbCup },
    plant: { iconType: PiPlantLight },
    flower: { iconType: IoMdFlower },
    turtle: { iconType: GiTurtle },
    cat: { iconType: FaCat },
    dog: { iconType: FaDog },
    bird: { iconType: LuBird },
    smile: { iconType: BsEmojiSmile },
    heart: { iconType: BsEmojiHeartEyes },
    wink: { iconType: BsEmojiWink },
    sunglasses: { iconType: BsEmojiSunglasses },
    custom: { iconType: GoAlert }
};

// export the list of keywords used in avatarsList
export const avatarKeys = Object.keys(avatarsList) as string[];

export type AvatarProps = {
    avatarKey: string;
    className?: string;
};

//return the react-icon component according to the assigned keyword
export function Avatar({ avatarKey, className }: AvatarProps) {
    const Icon = avatarsList[avatarKey].iconType
    return (<Icon className={className} />)
}
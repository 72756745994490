
import { zodResolver } from '@hookform/resolvers/zod';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import useAuth from '../../hooks/useAuth';
import { logUser, UserLoginFailError, UserNotFoundError } from '../../app/api';
import { showToast } from '../../utils/Toast';
import { useTranslation } from 'react-i18next';

// Form validation schema
const loginSchema = z.object({
    username: z.string().trim().min(1).max(256),
    password: z.string().min(1).max(256),
})

// TS type created from form validation schema
type LoginValues = z.infer<typeof loginSchema>;


export function Login() {

    const { t } = useTranslation();

    const { setAuth } = useAuth(); // set session informations (visible in the localstorage)

    // Form values and states
    const { register, handleSubmit, formState: { errors } } = useForm<LoginValues>({
        resolver: zodResolver(loginSchema),
    });


    const onLogin = async (data: LoginValues) => {
        try {
            const response = await logUser(data);
            //login good
            setAuth({ 
                refreshToken: response.refreshToken,
                accessToken: response.accessToken,
                role: response.userResponse.fk_role_id, 
                user: {
                    _id: response.userResponse._id,
                    username: response.userResponse.username,
                    email: response.userResponse.email,
                    avatar_key: response.userResponse.avatar_key,
                    createdAt: response.userResponse.createdAt,
                    login_count: response.userResponse.login_count,
                    time_spent_in_minutes: response.userResponse.time_spent_in_minutes,
                    fk_role_id: response.userResponse.fk_role_id,
                    fk_country_id: response.userResponse.fk_country_id,
                    fk_subgroup_id: response.userResponse.fk_subgroup_id
                } });
            localStorage.setItem('timespent', new Date().getTime().toString());
            showToast(t('Login.LoginSuccess'), 'success');
        } catch (error) {
            if (error instanceof UserLoginFailError) {
                showToast(t('Login.LoginFailPassword'), 'error');
            } else if (error instanceof UserNotFoundError) {
                showToast(t('Login.LoginFailUser'), 'error');
            } else {
                console.error(error);
            }
        }
        
    }

    
    return (
        <>
            <div className="flex min-h-full flex-col justify-center px-6 pt-0 py-12 border-b border-primary-100 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="mt-10 text-center text-3xl  font-medium leading-6 tracking-tight text-gray-900">
                        {t('Login.LoginTitle')}
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-8" action="#" onSubmit={handleSubmit(onLogin)}>
                        <div>
                            <label htmlFor="username" className="block text-base font-medium leading-6 text-gray-900">
                                {t('Login.FormUsername')}
                            </label>
                            <div className="mt-2">
                                <input
                                    id="username"
                                    type="text"
                                    required
                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                    {...register('username')}
                                />
                            </div>
                            <div className="mt-1 text-xs text-red-500" role="alert">
                                {errors.username?.message}
                            </div>
                        </div>

                        <div>
                            <div className="flex items-center justify-between">
                                <label htmlFor="password" className="block text-base font-medium leading-6 text-gray-900">
                                    {t('Login.FormPassword')}
                                </label>
                                <div className="text-sm">
                                    <Link
                                        to="/forgot-password"
                                        className="flex w-full justify-center text-base font-semibold leading-6 text-primary-500 :bg-gray-50 hover:border-primary-700 hover:text-primary-700"
                                    >
                                        {t('Login.FormForgotPassword')}
                                    </Link>
                                </div>
                            </div>
                            <div className="mt-2">
                                <input
                                    id="password"
                                    type="password"
                                    required
                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                    {...register('password')}
                                />
                            </div>
                            <div className="mt-1 text-xs text-red-500" role="alert">
                                {errors.password?.message}
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
                            >
                                {t('Login.FormLogin')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className='px-6 py-12 lg:px-8'>
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <Link
                        to="/signup"
                        className="flex w-full justify-center rounded-md bg-white border-2 border-primary-500 px-4 py-2 text-base font-semibold leading-6 text-primary-500 shadow-sm hover:bg-gray-50 hover:border-primary-700 hover:text-primary-700"
                    >
                        {t('Login.FormCreateAccount')}
                    </Link>
                </div>
            </div>
        </>
    )
}
import { Link } from 'react-router-dom';
import { HiOutlineArrowRight } from "react-icons/hi"
import { GoCommentDiscussion } from "react-icons/go"
import { BiFolder } from "react-icons/bi"
import { useTranslation } from 'react-i18next';

/* Component used by the HomePage */

export type CardProps = {
    to: string;
    title: string;
    description: string;
    isDeleted: boolean;
    isAdmin: boolean;
};

function DiscussionCard({ to, title, description, isDeleted, isAdmin }: CardProps) {
    const { t } = useTranslation();
    return (
        <div className="h-full p-6 bg-white border border-b-4 border-b-secondaryGreen-200 rounded-lg">
            <div className="flex items-center space-x-4">
                <GoCommentDiscussion className="w-9 h-9 text-secondaryGreen-500 mb-3" />
                <Link to={`/discussions/${to}`}>
                    <h5 className={`mb-2 text-2xl font-bold text-gray-900 dark:text-gray 
                        ${isDeleted ? 'text-warningRose-500' : 'text-gray-900'}`}>
                            {isDeleted && !isAdmin ? "Discussion supprimée." : title}
                    </h5>
                </Link>
            </div>
            <p className={`mb-3 font-normal text-gray-700 ${isDeleted ? 'text-warningRose-500' : 'text-gray-700'}`}>
                {isDeleted && !isAdmin ? "" : description.substring(0, 250)}...
            </p>
            <Link to={`/discussions/${to}`} className="inline-flex items-center mt-2 px-3 py-2 text-sm font-medium text-center text-white bg-secondaryGreen-500 rounded-lg hover:bg-secondaryGreen-700 focus:ring-4 focus:outline-none focus:ring-secondaryGreen-200 dark:bg-secondaryGreen-600 dark:hover:bg-secondaryGreen-700 dark:focus:ring-secondaryGreen-800">
                {t('Card.ReadNext')}
                <HiOutlineArrowRight className="w-3.5 h-3.5 ml-2" />
            </Link>
        </div>
    );
}

function ModuleCard({ to, title, description }: CardProps) {
    return (
        <Link to={`/modules/${to}`}>
            <div className="p-6 bg-white border border-t-8 border-t-secondaryRed-200 rounded-lg shadow">
                <div className="flex items-center space-x-4">
                    <BiFolder className="w-9 h-9 text-secondaryRed-500" />
                    {/*<a href="#">*/}
                        <h5 className="mb-2 text-2xl font-bold text-gray-900 ">{title}</h5>
                    {/*</a>*/}
                </div>
                <p className="mb-3 font-normal text-gray-700">{description}...</p>
            </div>
        </Link>
    );
}

export { DiscussionCard, ModuleCard };
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from "../hooks/useAuth";

export function PublicContent({
    children = <Outlet />,
}: {
    children?: JSX.Element;
}) {

    const { auth } = useAuth();

    // if an active session exists, go index child route (PrivatesRoutes)
    if (auth) {
        return <Navigate to="/" replace />;
    }

    // return first child route defined in publicRoutes
    return children;
}
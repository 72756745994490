import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { RootLayout } from './layouts/RootLayout';
import { ErrorLayout } from './layouts/ErrorLayout';
import { privateRoutes } from './routes/PrivatesRoutes';
import { publicRoutes } from './routes/PublicRoutes';
import { ToastContainer, ToastPosition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useAuth from './hooks/useAuth';
import { setAuthApi } from "./app/apiTokenClient";

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en/translation.json';
import frTranslation from './locales/fr/translation.json';
import IdleTimer from 'react-idle-timer';
import { updateTimeSpent } from './app/api';

// translation
let lang = localStorage.getItem('language');
if (!lang) {
  lang = 'fr';
}

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      fr: {
        translation: frTranslation
      }
    },
    lng: lang,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });


// router defines a layout where child routes listed from 
// the most generic to the most specific will be loaded
const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <ErrorLayout />,
    children: [
      ...publicRoutes,
      ...privateRoutes,
    ],
  },
]);


export function App() {
  const { auth, setAuth } = useAuth();


  const handleLoad = () => {
    if (auth) {
      localStorage.setItem('timespent', new Date().getTime().toString());
    }
  };

  const handleUnload = (event: BeforeUnloadEvent) => {
    if (auth) {
      let timespent = localStorage.getItem('timespent');
        if(timespent!==""){
            localStorage.setItem('timespent', "");
            let time = new Date().getTime() - parseInt(timespent!);
            time = Math.floor(time / 60000);
            // send to backend
            updateTimeSpent(auth?.user._id, time);
        }else{
            console.log("maybe problem with the time spent")
        }
    }
  };

  window.addEventListener('load', handleLoad);
  window.addEventListener('beforeunload', handleUnload);

  setAuthApi(auth, setAuth);
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer position="bottom-right" autoClose={2000} />
    </>
  );
} 


import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import useAuth from "../hooks/useAuth";
import { Navbar } from './Navbar';
import { Footer } from './Footer';
import { Main } from './Main';
import { updateTimeSpent } from '../app/api';


export function RootLayout() {

    const { auth, deleteAuth } = useAuth(); // auth: session informations (visible in the localstorage)

    const navigate = useNavigate();


    const onIdle = () => {
        // If there's no active session, nothing to do.
        if (!auth) {
            return;
        }

        let timespent = localStorage.getItem('timespent');
        if(timespent!==""){
            localStorage.setItem('timespent', "");
            let time = new Date().getTime() - parseInt(timespent!);
            time = Math.floor(time / 60000);
            // send to backend
            if(auth){
                updateTimeSpent(auth?.user._id, time);
            }
        }else{
            console.log("maybe problem with the time spent")
        }

        deleteAuth()

        navigate('/logout?idle=true', { replace: true });
    };

    // idle timer
    useIdleTimer({
        onIdle,
        timeout: 1000 * 60 * 30, // 30 minutes
    });

    
    return (
        <>
            <Navbar />
            <Main />
            <Footer />
            <Toaster position="top-right" /> {/* CustomToast component position */}
        </>
    );
}

/* Generic functions for storing data in localstorage */

export const getLocalData = <T>(key: string): T | undefined => {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : undefined;
};

export const setLocalData = <T>(key: string, data: T) => {
    localStorage.setItem(key, JSON.stringify(data));
};

export const deleteLocalData = (key: string) => {
    localStorage.removeItem(key);
};

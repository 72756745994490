
import { useEffect, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { z } from 'zod';
import { Avatar, avatarKeys } from '../components/Avatar';
import useAuth from '../hooks/useAuth';
import { usernameReg, passwordReg } from '../utils/regex'
import { modifyUser, modifyPassword, getImage, updateImage } from '../app/api';
import { showToast } from '../utils/Toast';
import { useTranslation } from 'react-i18next';







/* ---------------- */
/* INFORMATION FORM */
/* ---------------- */

function InformationFrom() {

    const { t } = useTranslation();

    const { auth } = useAuth();
    const currentUserAvatarKey: string = auth?.user.avatar_key || ""
    const currentUserEmail: string = auth?.user.email || ""
    const currentUserUsername: string = auth?.user.username || ""
    const currentUserCountry: string = auth?.user.fk_country_id || ""
    const currentUserSubgroup: number = auth?.user.fk_subgroup_id || 0
    const currentLoginCount: number = auth?.user.login_count || 0
    const currentTimeSpent: number = auth?.user.time_spent_in_minutes || 0
    const [image, setImage] = useState("")
    const [imageFile, setImageFile] = useState<File | null>(null)

    const [selectedAvatarKey, setSelectedAvatarKey] = useState<string>(currentUserAvatarKey)


    // Information form validation schema
    const informationSchema = z.object({
        username: z.string().trim().min(1).max(256).regex(usernameReg, {
            message: t('AccountPage.UsernameCheck')
        }),
        avatarKey: z.enum(avatarKeys as [string, ...string[]])
    })

    // TS type created from information form validation schema
    type InformationValues = z.infer<typeof informationSchema>;



    // Information form values and states 
    const { register, handleSubmit, setValue, trigger, formState: { errors, isDirty } } = useForm<InformationValues>({
        defaultValues: {
            username: currentUserUsername,
            avatarKey: currentUserAvatarKey
        },
        mode: "onBlur",
        resolver: zodResolver(informationSchema),
    });


    // set the form's "avatarKey" value when one of the avatar buttons is clicked
    const handleAvatarClick = (key: string) => {
        setValue("avatarKey", key)
        trigger("avatarKey")
    }


    const { setAuth } = useAuth();

    const checkAvatar = async () => {
        if(auth?.user.avatar_key==="custom"){
            const base64Img : any = await getImage(auth?.user._id);
            setImage(base64Img.data.image);
        }
    };

    useEffect(() => {
        checkAvatar();
    }, []);

    const onSave = async (data: InformationValues) => {
        if(!auth){
        }else{
            try{
                const response = await modifyUser({
                    _id: auth.user._id,
                    username: data.username,
                    email: auth.user.email,
                    avatar_key: data.avatarKey,
                    createdAt: auth.user.createdAt,
                    login_count: auth.user.login_count,
                    time_spent_in_minutes: auth.user.time_spent_in_minutes,
                    fk_role_id: auth.user.fk_role_id,
                    fk_country_id: auth.user.fk_country_id,
                    fk_subgroup_id: auth.user.fk_subgroup_id
                });
                setAuth({
                    refreshToken: auth.refreshToken,
                    accessToken: auth.accessToken,
                    role: auth.user.fk_role_id,
                    user: {
                        _id: auth.user._id,
                        username: data.username,
                        email: auth.user.email,
                        avatar_key: data.avatarKey,
                        createdAt: auth.user.createdAt,
                        login_count: auth.user.login_count,
                        time_spent_in_minutes: auth.user.time_spent_in_minutes,
                        fk_role_id: auth.user.fk_role_id,
                        fk_country_id: auth.user.fk_country_id,
                        fk_subgroup_id: auth.user.fk_subgroup_id
                    }
                });

                if(data.avatarKey==="custom" && imageFile){
                    let base64Img : any = await new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(imageFile);
                        reader.onload = () => resolve(reader.result);
                        reader.onerror = error => reject(error);
                    });
                    while((base64Img.length * (3/4)) - (base64Img.split('=')[1]?.length || 0) > 100000){
                        // reduce the quality of the image until it is under 100kb
                        const quality = parseFloat((100 * 100000 / (base64Img.length * (3/4))).toFixed(2));
                        base64Img = await new Promise((resolve, reject) => {
                            const img = new Image();
                            img.src = base64Img;
                            img.onload = () => {
                                const canvas = document.createElement('canvas');
                                const ctx = canvas.getContext('2d');
                                if(!ctx) reject('No context');
                                canvas.width = img.width;
                                canvas.height = img.height;
                                ctx?.drawImage(img, 0, 0);
                                canvas.toBlob((blob: any) => {
                                    if(!blob) reject('No blob');
                                    const file = new File([blob], 'image', {type: 'image/jpeg', lastModified: Date.now()});
                                    const reader = new FileReader();
                                    reader.readAsDataURL(file);
                                    reader.onload = () => resolve(reader.result);
                                    reader.onerror = error => reject(error);
                                }, 'image/jpeg', quality/100);
                            };
                            img.onerror = error => reject(error);
                        });
                    }
                    const userId : any = auth.user._id;
                    const responseImage = await updateImage({
                        _id: "",
                        image: base64Img,
                        fk_user_id: userId
                    });
                }
                showToast(t('AccountPage.ModificationSuccess'), 'success');
            }catch(error){
                showToast(t('AccountPage.ModificationError'), 'error');
                console.error(error);
            }
            
        }
    };

    const selectImage = async () => {
        // Create a 'hidden' input element
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/png, image/jpeg'; // specify accepted file types
        input.onchange = (event) => {
            if(!event.target) return;
            const target = event.target as HTMLInputElement;
            if(!target.files) return;
            const file = target.files[0];
            // handle the file here
            setImageFile(file);
            setImage(URL.createObjectURL(file));
        };
        // Trigger the file dialog
        input.click();
    }


    return (
        <form onSubmit={handleSubmit(onSave)}>
            <div className="flex flex-col justify-center gap-8 md:flex-row md:gap-16">
                <div className="sm:w-full">
                    <div className="space-y-6 mb-10">
                        <div>
                            <label htmlFor="email" className="block text-base font-medium leading-6 text-gray-900">
                                {t('AccountPage.FormEmail')}
                            </label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    type="email"
                                    value={currentUserEmail}
                                    disabled
                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="username" className="block text-base font-medium leading-6 text-gray-900">
                                {t('AccountPage.FormUsername')}
                            </label>
                            <div className="mt-2">
                                <input
                                    id="username"
                                    type="text"
                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                    {...register('username')}
                                />
                            </div>
                            {errors.username ? (
                                <div className="mt-1 text-xs text-red-500" role="alert">
                                    {errors.username.message}
                                </div>
                            ) :
                                <div className="mt-1 text-xs text-neutral-500">
                                    {t('AccountPage.FormUsernameCheck')}
                                </div>
                            }
                        </div>
                        <div>
                            <label htmlFor="pays" className="block text-base font-medium leading-6 text-gray-900">
                                {t('AccountPage.FormCountry')}
                            </label>
                            <div className="mt-2">
                                <input
                                    id="pays"
                                    value={currentUserCountry}
                                    disabled
                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="groupe" className="block text-base font-medium leading-6 text-gray-900">
                                {t('AccountPage.FormGroup')}
                            </label>
                            <div className="mt-2">
                                <input
                                    id="groupe"
                                    value={currentUserSubgroup}
                                    disabled
                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="loginCount" className="block text-base font-medium leading-6 text-gray-900">
                                {t('AccountPage.FormLoginCount')}
                            </label>
                            <div className="mt-2">
                                <input
                                    id="loginCount"
                                    value={currentLoginCount}
                                    disabled
                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="timeSpent" className="block text-base font-medium leading-6 text-gray-900">
                                {t('AccountPage.FormTimeSpent')}
                            </label>
                            <div className="mt-2">
                                <input
                                    id="timeSpent"
                                    value={currentTimeSpent}
                                    disabled
                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sm:w-full md:max-w-xs">
                    <p className="block text-base font-medium leading-6 text-gray-900">
                        {t('AccountPage.FormAvatar')}
                    </p>
                    <div className="grid grid-cols-5 gap-4 place-items-center mt-2 p-6 bg-white rounded-md shadow-md sm:grid-cols-4 sm:p-8 mb-10">
                    {avatarKeys.map((item) => {
                            if (item === 'custom') {
                                return null; 
                            }

                            return (
                                <button
                                    type="button"
                                    key={item}
                                    onClick={() => {
                                        setSelectedAvatarKey(item);
                                        handleAvatarClick(item);
                                    }}
                                    className={clsx(
                                        item === selectedAvatarKey
                                            ? 'border-4 border-primary-400'
                                            : 'border border-gray-200 hover:border-4 hover:border-primary-100',
                                        'w-12 h-12 flex justify-center items-center rounded-md'
                                    )}
                                >
                                    <Avatar avatarKey={item} className="w-7 h-7" />
                                </button>
                        )})}
                        {image === "" ? (null) : (
                            <button
                                type="button"
                                onClick={() => {
                                    setSelectedAvatarKey("custom");
                                    handleAvatarClick("custom");
                                }}
                                className={clsx(
                                    "custom" === selectedAvatarKey
                                        ? 'border-4 border-primary-400'
                                        : 'border border-gray-200 hover:border-4 hover:border-primary-100',
                                    'w-12 h-12 flex justify-center items-center rounded-md'
                                )}
                            >
                                <img alt="preview profile picture" className="object-cover w-full h-full" src={image}/>
                            </button>
                        )}
                    </div>
                    <div className='flex flex-col justify-center gap-8 md:flex-row md:gap-16'>
                            <button
                            type="button"
                            onClick={() => {
                                selectImage();
                                setSelectedAvatarKey("custom");
                                handleAvatarClick("custom");
                            }}
                            className="flex w-full justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
                        >
                            {t('AccountPage.FormCustomImage')}
                        </button>
                        
                        
                    </div>
                </div>
            </div>
            <div className="mt-12">
                <button
                    type="submit"
                    disabled={(currentUserAvatarKey === selectedAvatarKey) && (!isDirty) && (!imageFile)}
                    className="flex w-full justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm disabled:opacity-50 enabled:hover:bg-primary-400 enabled:focus-visible:outline enabled:focus-visible:outline-2 enabled:focus-visible:outline-offset-2 enabled:focus-visible:outline-primary-500"
                >
                    {t('AccountPage.FormSave')}
                </button>
            </div>
        </form>
    );
}


/* -------------------- */
/* PASSWORD CHANGE FORM */
/* -------------------- */

function ChangePwdFrom() {

    const { t } = useTranslation();

    // Password change form validation schema
    const changePwdSchema = z
    .object({
        currentPwd: z.string(),
        newPwd: z.string().min(8).max(256).regex(passwordReg, {
            message: t('AccountPage.CheckPassword')
        }),
        confirmNewPwd: z.string().min(8).max(256)
    })
    .refine((data) => data.newPwd === data.confirmNewPwd, {
        message: t('AccountPage.CheckSamePassword'),
        path: ['confirmNewPwd'],
    });

    // TS type created from Password change form validation schema
    type ChangePwdValues = z.infer<typeof changePwdSchema>;

    // Password change form values and states 
    const { register, handleSubmit, formState: { errors } } = useForm<ChangePwdValues>({
        mode: "onBlur",
        resolver: zodResolver(changePwdSchema),
    });

    const { auth } = useAuth();

    const onChangePwd = async (data: ChangePwdValues) => {
        // TODO: API call user:put (password)

        if(!auth){
        }else{
            try{
                const response = await modifyPassword(auth.user._id, data.currentPwd, data.newPwd);
                showToast(t('AccountPage.PasswordChangeSuccess'), 'success');
            }catch(error){
                showToast(t('AccountPage.PasswordChangeError'), 'error');
                console.error(error);
            }
        }
        

    };

    return (
        <form onSubmit={handleSubmit(onChangePwd)}>
            <div className="space-y-6 mt-6">
                <div>
                    <label htmlFor="currentPwd" className="block text-base font-medium leading-6 text-gray-900">
                        {t('AccountPage.FormCurrentPassword')}
                    </label>
                    <div className="mt-2">
                        <input
                            id="currentPwd"
                            type="password"
                            required
                            className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                            {...register('currentPwd')}
                        />
                    </div>
                    <div className="mt-1 text-xs text-red-500" role="alert">
                        {errors.currentPwd?.message}
                    </div>
                </div>
                <div>
                    <label htmlFor="newPwd" className="block text-base font-medium leading-6 text-gray-900">
                        {t('AccountPage.FormNewPassword')}
                    </label>
                    <div className="mt-2">
                        <input
                            id="newPwd"
                            type="password"
                            required
                            className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                            {...register('newPwd')}
                        />
                    </div>
                    {errors.newPwd ? (
                        <div className="mt-1 text-xs text-red-500" role="alert">
                            {errors.newPwd.message}
                        </div>
                    ) :
                        <div className="mt-1 text-xs text-neutral-500">
                            {t('AccountPage.FormNewPasswordCheck')}
                        </div>
                    }
                </div>
                <div>
                    <label htmlFor="confirmNewPwd" className="block text-base font-medium leading-6 text-gray-900">
                        {t('AccountPage.FormPasswordConfirm')}
                    </label>
                    <div className="mt-2">
                        <input
                            id="confirmNewPwd"
                            type="password"
                            required
                            className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                            {...register('confirmNewPwd')}
                        />
                    </div>
                    <div className="mt-1 text-xs text-red-500" role="alert">
                        {errors.confirmNewPwd?.message}
                    </div>
                </div>
            </div>
            <div className="mt-10">
                <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm disabled:opacity-50 enabled:hover:bg-primary-400 enabled:focus-visible:outline enabled:focus-visible:outline-2 enabled:focus-visible:outline-offset-2 enabled:focus-visible:outline-primary-500"
                >
                    {t('AccountPage.FormSaveNewPassword')}
                </button>
            </div>
        </form>
    );
}


/* -------------- */
/* PAGE RENDERING */
/* -------------- */

export function AccountPage() {

    const { t } = useTranslation();

    return (
        <>
            <div className="flex min-h-full flex-col justify-center px-6 pt-0 py-12 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h2 className="mt-10 text-center text-3xl  font-medium leading-6 tracking-tight text-gray-900 md:mb-5">
                        {t('AccountPage.MyInformations')}
                    </h2>
                </div>

                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm md:max-w-3xl">

                    <InformationFrom />

                    <div className="mt-12">
                        <Disclosure>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className={`${open ? '' : 'no-underline'} font-medium hover:underline hover:underline-offset-4 hover:decoration-primary-300`}>
                                        {open ? (
                                            <span> {t('AccountPage.Cancel')} </span>
                                        ) : (
                                            <span>{t('AccountPage.ChangePassword')} </span>
                                        )}
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="pl-4 ml-1 border-l-2 border-gray-200">

                                        <ChangePwdFrom />

                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    </div>
                </div >
            </div >

        </>
    )
}
import { useState, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import { showToast } from '../../utils/Toast';
import Spinner from '../../components/Spinner';
import { useTranslation } from 'react-i18next';
import { MdLogin } from 'react-icons/md';
import { forgotPassword } from '../../app/api';


// Form validation schema
const forgotPassSchema = z
    .object({
        email: z.string().trim().min(1).max(256).email(),
    });

// TS type created from form validation schema    
type ForgotValues = z.infer<typeof forgotPassSchema>;


export function ForgotPass() {

    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(true);
    const [formFinished, setFormFinished] = useState<boolean>(false);

    // Form values and states 
    const { register, handleSubmit, setValue, trigger, formState: { errors } } = useForm<ForgotValues>({
        mode: "onBlur",
        resolver: zodResolver(forgotPassSchema),
    });
    
    const navigate = useNavigate();

    // Fetch countries list
    useEffect(() => {
        setLoading(false);
    }, []);

    const onSubmit = async (data: ForgotValues) => {
        try{
            setLoading(true);
            const response = await forgotPassword(data.email);

            // redirect to login page
            showToast(t("ForgotPass.RequestSent"), 'success');
            setFormFinished(true);
            setLoading(false);
        }catch (error : any) {
            showToast(t("ForgotPass.RequestFailed"), 'error');
        }
    }

    return (
        <>
            {loading ? (
                <Spinner /> // render Spinner while loading
            ) : (
                <div className="flex min-h-full flex-col justify-center px-6 pt-0 py-12 lg:px-8">
                    <div className="sm:mx-auto sm:w-full">
                        <h2 className="mt-10 text-center text-3xl font-medium leading-6 tracking-tight text-gray-900 md:mb-5">
                            {t("ForgotPass.Title")}
                        </h2>
                    </div>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm md:max-w-3xl">
                        {!formFinished ? (
                            <form onSubmit={handleSubmit(onSubmit)}>

                                <div className="flex flex-col justify-center gap-8 md:flex-row md:gap-16">
                                    <div className="space-y-6 sm:w-full">
                                        <div>
                                            <label htmlFor="email" className="block text-base font-medium leading-6 text-gray-900">
                                            {t("ForgotPass.Email")}
                                            </label>
                                            <div className="mt-2">
                                                <input
                                                    id="email"
                                                    type="email"
                                                    required
                                                    className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                                    {...register('email')}
                                                />
                                            </div>
                                            <div className="mt-1 text-xs text-red-500" role="alert">
                                                {errors.email?.message}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-12">
                                    <button
                                        type="submit"
                                        className="flex w-full justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
                                    >
                                        {t("ForgotPass.Submit")}
                                    </button>
                                </div>

                            </form>
                        ): (
                            <div className="flex flex-col items-center justify-center gap-4">
                                <p className="text-base text-center font-medium text-gray-900">
                                    {t("ForgotPass.SuccessMessage")}
                                </p>
                                <Link to="/login" className="mt-8 flex gap-1.5 items-center justify-center text-base text-center font-medium text-primary-400 hover:text-primary-500">
                                    <MdLogin className="w-3.5 h-3.5" />
                                    {t("ForgotPass.BackToLogin")}
                                </Link>
                            </div>
                        )}

                    </div >


                </div >
            )}
        </>
    )
}
import { Message, Discussion } from "../../app/models"
import { PostedMessage } from "./PostedMessage";

/* Component used to recursively build the forum */

export type ActionOnMessageProps = {
    onAdd: (newMessage: Message) => void, // defined in DiscussionPage
    onEdit: (editedMessage: Message) => void, // defined in DiscussionPage
    onDelete: (message: Message) => void // defined in DiscussionPage
}

export type MessageContainerProps = {
    messages: Message[];
    isPersonal: boolean;
    parent: Discussion | Message;
} & ActionOnMessageProps;


export function MessageContainer({
    messages,
    onAdd,
    onEdit,
    onDelete,
    isPersonal,
    parent
}: MessageContainerProps) {

    // return a PostedMessage component, which in turn returns a MessageContainer
    // component for possible replies, etc...
    return (
        <div id="messagesContainer">
            {messages.map((message: Message) => (
                <PostedMessage key={message._id} message={message} onAdd={onAdd} onEdit={onEdit} onDelete={onDelete} isPersonal={isPersonal} parent={parent} />
            ))}
        </div>
    );
}
import { useState, useEffect } from "react";
import { zodResolver } from '@hookform/resolvers/zod';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { GoPlusCircle } from "react-icons/go"
import { z } from 'zod';
import { getModule, createDiscussion, getSubgroups } from '../app/api'
import useApi from '../hooks/useApi';
import { ErrorLayout } from "../layouts/ErrorLayout";
import { DiscussionCard } from "../components/Card"
import FormModal from "../components/modals/FormModal";
import { NewDiscussionForm } from "../components/NewDiscussionForm";
import Spinner from '../components/Spinner'
import { showToast } from '../utils/Toast';
import useAuth from "../hooks/useAuth";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


// Form validation schema
const schema = z.object({
  title: z.string().trim().min(1).max(256),
  firstMessage: z.string().trim().min(1).max(256),
})

// TS type created from form validation schema
export type SchemaValues = z.infer<typeof schema>;



export function ModulePage() {

  const { t } = useTranslation();

  const { id } = useParams() as { id: string }; // URL id

  const getModuleApi = useApi(getModule); // getModule API call states
  const [subgroups, setSubgroups] = useState([]); // Subgroups list


  const [isNewDiscussionModalOpen, setNewDiscussionModalOpen] = useState(false); // open or close the discussion creation modal

  // Form values and states
  const { register, handleSubmit, formState: { errors } } = useForm<SchemaValues>({
    resolver: zodResolver(schema),
  });


  useEffect(() => {
    let subgroupId = auth?.user.fk_subgroup_id;
    let country = auth?.user.fk_country_id;
    if(auth?.role === 'admin'){
      subgroupId = 1;
    }
    getModuleApi.request(id, subgroupId, country, false); // response: getModuleApi.data or getModuleApi.error

    // Get subgroups
    const fetchSubgroups = async () => {
      const response = await getSubgroups();
      setSubgroups(response.data);
    }

    fetchSubgroups();

  }, []);

  const { auth } = useAuth();
  const navigate = useNavigate();

  const onCreate = async (data: SchemaValues) => {
    if(!getModuleApi.data){
      showToast(t('ModulePage.CreateDiscError'), 'error');
      return <ErrorLayout />;
    }else {
      const userId = auth?.user._id;
      const subgroupNumber = auth?.user.fk_subgroup_id;
      if(!userId || !subgroupNumber){
        showToast(t('ModulePage.CreateDiscError'), 'error');
        return <ErrorLayout />;
      }
      const response = await createDiscussion(data.title, data.firstMessage, getModuleApi.data.id.toString(), userId, false, subgroupNumber);
      setNewDiscussionModalOpen(false);
      showToast(t('ModulePage.CreateDiscSuccess'), 'success');
      navigate('/discussions/'+response.data._id);
    }
    
  }

  const changeSubgroup = (subgroupNumber: any) => {
    let country = auth?.user.fk_country_id;
    getModuleApi.request(id, Number(subgroupNumber), country, false);
  }


  // Error page if bad id
  if (getModuleApi.isError) {
    return <ErrorLayout />
  }

  return (
    <>
      {getModuleApi.data && (
        <>
          <section className="mb-12 sm:mb-18 md:mb-20">
            <div className='p-6 border-2 bg-white border-secondaryRed-500 rounded-lg shadow-lg'>
              <div className="flex space-x-4 items-center mb-2 sm:mb-4">
                <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">{t('ModulePage.ModuleTitle')}</h3>
                <span className='h-8 w-px bg-secondaryRed-200'></span>
                <p className="text-xl font-medium text-secondaryRed-700">{t('Modules.'+getModuleApi.data.name+'.Type')}</p>
              </div>
              <p className="mb-1 font-medium text-gray-900">{t('ModulePage.ModuleDesc')}</p>
              <p className='text-base text-gray-500'>{t('Modules.'+getModuleApi.data.name+'.Description')}</p>
            </div>
          </section>      
          
          <section>
            <div className="flex flex-col gap-2 space-y-2 mb-12 md:flex-row md:items-center md:space-y-0">
              <h3 className="text-xl font-medium text-gray-900 sm:text-2xl">{t('ModulePage.DiscLinked')}</h3>
              {auth?.role === 'admin' ? (
                <select onChange={(e) => changeSubgroup(e.target.value)}
                  className="block bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500 md:ml-auto"
                >
                  {subgroups.map((item : any) => (
                    <option key={item.id} value={item.number}>{t('ModulePage.Group')}{item.number}</option>
                  ))}
                </select>
              ) : (
                <button
                  type="button"
                  onClick={() => { setNewDiscussionModalOpen(true) }}
                  className="flex gap-1.5 items-center font-bold text-secondaryGreen-400  hover:text-secondaryGreen-600 md:ml-auto"
                >
                  <GoPlusCircle className="w-7 h-7" /> {t('ModulePage.CreateNewDisc')}
                </button>
                )}
              
            </div>
            <div className="flex flex-col space-y-8">
              {getModuleApi.data.discussions.map((item) => (
                <span key={item._id}>
                  <DiscussionCard
                    key={item._id}
                    to={item._id}
                    title={item.title}
                    description={item.description[item.description.length - 1]}
                    isDeleted={item.deleted}
                    isAdmin={auth?.role === 'admin'}
                  />
                </span>
              ))}
            </div>
          </section>
        </>
      )}

      {/* Loading spinner */}
      {getModuleApi.isLoading && (
        <Spinner />
      )}

      {/* Discussion creation modal */}
      <FormModal
        isOpen={isNewDiscussionModalOpen}
        title={t('ModulePage.CreateNewDiscTitle')}
        content={<NewDiscussionForm register={register} errors={errors} />}
        cancel={() => { setNewDiscussionModalOpen(false) }}
        cancelLabel={t('ModulePage.Cancel')}
        confirm={handleSubmit(onCreate)}
        confirmLabel={t('ModulePage.Create')}
      />
    </>
  )
}
import { useState, useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import { showToast } from '../../utils/Toast';
import Spinner from '../../components/Spinner';
import { useTranslation } from 'react-i18next';
import { MdLogin } from 'react-icons/md';
import { passwordReg } from '../../utils/regex'
import { useParams } from 'react-router-dom';
import { resetPassword } from '../../app/api';



export function ResetPass() {

    const { t } = useTranslation();

    // Form validation schema
    const resetPassSchema = z
    .object({
        password: z.string().min(8).max(256).regex(passwordReg, {
            message: t("ResetPass.CheckPassword")
        }),
        confirm: z.string().min(8).max(256),
    })
    .refine((data) => data.password === data.confirm, {
        message: t("ResetPass.CheckConfirm"),
        path: ['confirm'],
    });

    // TS type created from form validation schema    
    type ResetValues = z.infer<typeof resetPassSchema>;

    const { token } = useParams() as { token: string };
    const { email } = useParams() as { email: string };

    const [loading, setLoading] = useState<boolean>(true);
    const [formFinished, setFormFinished] = useState<boolean>(false);

    // Form values and states 
    const { register, handleSubmit, setValue, trigger, formState: { errors } } = useForm<ResetValues>({
        mode: "onBlur",
        resolver: zodResolver(resetPassSchema),
    });
    
    const navigate = useNavigate();

    // Fetch countries list
    useEffect(() => {
        setLoading(false);
    }, []);

    const onSubmit = async (data: ResetValues) => {
        try{
            setLoading(true);
            const response = await resetPassword(email, data.password, token);

            // redirect to login page
            showToast(t("ResetPass.FormSuccess"), 'success');
            setFormFinished(true); 
            setLoading(false);
        }catch (error : any) {
            console.log(error);
            showToast(t("ResetPass.FormError"), 'error');
        }
    }

    return (
        <>
            {loading ? (
                <Spinner /> // render Spinner while loading
            ) : (
                <div className="flex min-h-full flex-col justify-center px-6 pt-0 py-12 lg:px-8">
                    <div className="sm:mx-auto sm:w-full">
                        <h2 className="mt-10 text-center text-3xl  font-medium leading-6 tracking-tight text-gray-900 md:mb-5">
                            {t("ResetPass.Title")}
                        </h2>
                    </div>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm md:max-w-3xl">
                        {!formFinished ? (
                            <form onSubmit={handleSubmit(onSubmit)}>

                                    <div>
                                        <label htmlFor="password" className="block text-base font-medium leading-6 text-gray-900">
                                            {t("ResetPass.FormPassword")}
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="password"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                                {...register('password')}
                                            />
                                        </div>
                                        {errors.password ? (
                                            <div className="mt-1 text-xs text-red-500" role="alert">
                                                {errors.password.message}
                                            </div>
                                        ) :
                                            <div className="mt-1 text-xs text-neutral-500">
                                                {t("ResetPass.FormPasswordCheck")}
                                            </div>
                                        }
                                    </div>
                                    <div>
                                        <label htmlFor="confirm" className="block text-base font-medium leading-6 text-gray-900">
                                            {t("ResetPass.FormPasswordConfirm")}
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                id="confirm"
                                                type="password"
                                                autoComplete="current-password"
                                                required
                                                className="block w-full rounded-md border-0 px-4 py-2 text-gray-900 shadow-sm ring-2 ring-inset ring-gray-200 placeholder:text-gray-400 focus:ring-1 focus:ring-primary-400 focus:outline-primary-400"
                                                {...register('confirm')}
                                            />
                                        </div>
                                        <div className="mt-1 text-xs text-red-500" role="alert">
                                            {errors.confirm?.message}
                                        </div>
                                    </div>

                                <div className="mt-12">
                                    <button
                                        type="submit"
                                        className="flex w-full justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
                                    >
                                        {t("ResetPass.Submit")}
                                    </button>
                                </div>

                            </form>
                        ): (
                            <div className="flex flex-col items-center justify-center gap-4">
                                <p className="text-base text-center font-medium text-gray-900">
                                    {t("ResetPass.SuccessMessage")} 
                                </p>
                                <Link to="/login" className="mt-8 flex gap-1.5 items-center justify-center text-base text-center font-medium text-primary-400 hover:text-primary-500">
                                    <MdLogin className="w-3.5 h-3.5" />
                                        {t("ResetPass.BackToLogin")}
                                </Link>
                            </div>
                        )}

                    </div >


                </div >
            )}
        </>
    )
}
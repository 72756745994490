import { UseFormRegister, FieldErrors } from 'react-hook-form';
import { SchemaValues } from "../pages/ModulePage"
import { useTranslation } from 'react-i18next';

/* Component used by the ModulePage and DiscussionListPage */

export type NewDiscussionFormProps = {
    register: UseFormRegister<SchemaValues>;
    errors: FieldErrors<SchemaValues>;
};

export function NewDiscussionForm({
    register,
    errors
}: NewDiscussionFormProps) {

    const { t } = useTranslation();

    return (
        <>
            <div>
                <div>
                    <label htmlFor="title" className="block text-base text-start font-medium leading-6 text-gray-900">
                    {t('NewDiscussionForm.Title')}
                    </label>
                    <div className="mt-2">
                        <input
                            id="title"
                            type="text"
                            className="w-full rounded-md p-2 border-2 border-primary-100/50 shadow-sm focus:outline-none"
                            required
                            {...register("title")}
                        />
                    </div>
                    <div className="mt-1 text-xs text-red-500" role="alert">
                        {errors.title?.message}
                    </div>
                </div>

                <div className="mt-4">
                    <label htmlFor="firstMessage" className="block text-base text-start font-medium leading-6 text-gray-900">
                    {t('NewDiscussionForm.FirstMessage')}
                    </label>
                    <div className="mt-2">
                        <textarea
                            id="firstMessage"
                            className="h-44 w-full rounded-md p-2 border-2 border-primary-100/50 shadow-sm resize-none focus:outline-none"
                            required
                            {...register("firstMessage")}
                        />
                    </div>
                    <div className="mt-1 text-xs text-red-500" role="alert">
                        {errors.firstMessage?.message}
                    </div>
                </div>
            </div>
        </>
    )
}
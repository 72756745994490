import { useEffect, useState } from "react"
import { Stat, Stats } from "../app/models";
import { getStats } from '../app/api'
import Spinner from "../components/Spinner"
import useAuth from '../hooks/useAuth';
import { ErrorLayout } from "../layouts/ErrorLayout";
import { useTranslation } from 'react-i18next';
import React from "react";

import { GoArrowDown, GoArrowUp } from "react-icons/go";
import { FaFilter } from "react-icons/fa";
import Modal from 'react-modal';

import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  RowData,
  Table,
  flexRender,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  FilterFn,
} from '@tanstack/react-table'

import dateBetweenFilterFn from "../utils/DateFilter";
import dayjs from 'dayjs';

Modal.setAppElement('#root');

declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'range' | 'select' | 'date';
  }
}

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
    dateBetweenFilterFn: FilterFn<unknown>;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  const rowValue = row.getValue(columnId);
  if (typeof rowValue !== 'string') return false;
  return rowValue.toLowerCase().includes(value.toLowerCase());
};



export function StatsPage() {

  const { t } = useTranslation();

  const { auth, setAuth } = useAuth();


  const [data, setData] = React.useState<Stats>(() => []);

  const fetchData = async () => {
    const response = await getStats(auth?.user.fk_country_id || '');
    setData(response.data);
    // change subgroupId to string
    for (let i = 0; i < response.data.length; i++) {
      response.data[i].subgroupId = response.data[i].subgroupId.toString();
    }
  };

  const exportData = async () => {
    const csv = data.map(row => {
      return Object.values(row).join(",");
    }).join("\n");
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement
      ('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'stats.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    fetchData();
  }, []);


  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )

  const columns = React.useMemo<ColumnDef<Stat, any>[]>(
    () => [
      {
        accessorKey: '_id',
        cell: info => info.getValue(),
        header: () => <span>ID</span>,
        meta: {
          filterVariant: 'text',
        },
      },
      {
        accessorKey: 'email',
        cell: info => info.getValue(),
        header: () => <span>Email</span>,
        meta: {
          filterVariant: 'text',
        },
      },
      {
        accessorFn: row => row.username,
        id: 'username',
        cell: info => info.getValue(),
        header: () => <span>{t("StatsPage.Username")}</span>,
        meta: {
          filterVariant: 'text',
        },
      },
      {
        accessorKey: 'createdAt',
        cell: (cell) => dayjs(cell.getValue()).format('DD/MM/YYYY'),
        header: () => <span>{t("StatsPage.CreatedAt")}</span>,
        filterFn: 'dateBetweenFilterFn',
        meta: {
          filterVariant: 'date',
        },
      },
      {
        accessorKey: 'subgroupId',
        cell: info => info.getValue(),
        header: () => <span>{t("StatsPage.Subgroup")}</span>,
        meta: {
          filterVariant: 'select',
        },
      },
      {
        accessorKey: 'country',
        cell: info => info.getValue(),
        meta: {
          filterVariant: 'select',
        },
        header: () => <span>{t("StatsPage.Country")}</span>,
      },
      {
        accessorKey: 'discussionsCount',
        cell: info => info.getValue(),
        meta: {
          filterVariant: 'range',
        },
        header: () => <span>{t("StatsPage.DiscussionCount")}</span>,
      },
      {
        accessorKey: 'messagesCount',
        cell: info => info.getValue(),
        meta: {
          filterVariant: 'range',
        },
        header: () => <span>{t("StatsPage.MessageCount")}</span>,
      },
      {
        accessorKey: 'loginCount',
        cell: info => info.getValue(),
        meta: {
          filterVariant: 'range',
        },
        header: () => <span>{t("StatsPage.LoginCount")}</span>,
      },
      {
        accessorKey: 'timeSpent',
        cell: info => info.getValue(),
        meta: {
          filterVariant: 'range',
        },
        header: () => <span>{t("StatsPage.TimeSpent")}</span>,
      },
    ],
    []
  );

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
      dateBetweenFilterFn: dateBetweenFilterFn,
    },
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  })

  if (!data) return <Spinner />;

  if (auth?.role !== 'admin') return <ErrorLayout />;


  return (
    <>  <h2 className="text-3xl text-gray-900 font-bold tracking-tight">{t("StatsPage.StatsTitle")}</h2>
      <br></br>
      <br></br>

      {data && (
        <section>
          <div className="rounded-md overflow-x-auto scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-thumb-primary-300 "> {/* scrollbar-track-primary-100 */}
            <table className="w-full divide-y divide-x divide-gray-200 text-left">
              <thead className="bg-primary-500">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th key={header.id} colSpan={header.colSpan} className="px-6 py-3 font-medium text-white tracking-wider">
                          {header.isPlaceholder ? null : (
                            <>
                              <div className="flex items-center gap-2 w-max-content">
                                <div
                                  {...{
                                    className: header.column.getCanSort()
                                      ? 'cursor-pointer select-none'
                                      : '',
                                    onClick: header.column.getToggleSortingHandler(),
                                  }}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {{
                                    asc: <GoArrowUp className="inline" />,
                                    desc: <GoArrowDown className="inline" />,
                                  }[header.column.getIsSorted() as string] ?? null}
                                </div>
                                {header.column.getCanFilter() ? (
                                  <div>
                                    <Filter column={header.column} table={table} />
                                  </div>
                                ) : null}
                              </div>
                            </>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => {
                  return (
                    <tr key={row.id} className="bg-white even:bg-gray-50 divide-x">
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <td key={cell.id} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="h-2" />
          </div>

          {/* controls */}
          <div className="h-4" />
          <div className="flex items-center gap-2">
            <button
              className="flex justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              {'<<'}
            </button>
            <button
              className="flex justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              {'<'}
            </button>
            <button
              className="flex justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              {'>'}
            </button>
            <button
              className="flex justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              {'>>'}
            </button>
            <span className="flex items-center gap-1">
              <div>Page</div>
              <strong>
                {table.getState().pagination.pageIndex + 1} {t("StatsPage.PageOf")}{' '}
                {table.getPageCount()}
              </strong>
            </span>
            <span className="flex items-center gap-1">
              | {t("StatsPage.GoToPage")}
              <input
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
                className="flex w-20 justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
              />
            </span>
            <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
              className="flex justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
            >
              {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {t("StatsPage.Show")} {pageSize}
                </option>
              ))}
            </select>

            <div className="ml-auto">
              <button onClick={() => fetchData()} className="flex justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500">{t("StatsPage.RefreshBtn")}</button>
            </div>
            <div>
              <button onClick={() => exportData()} className="flex justify-center rounded-md bg-primary-500 px-4 py-2 text-base font-semibold leading-6 text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500">{t("StatsPage.ExportBtn")}</button>
            </div>

          </div>
        </section>
      )}
    </>
  );
}

function isValidDate(d: any) {
  const parsedDate = new Date(d);
  return parsedDate instanceof Date && !Number.isNaN(parsedDate);
}



function Filter({
  column,
  table,
}: {
  column: Column<any, unknown>;
  table: Table<any>;
}) {
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const { t } = useTranslation();

  const { filterVariant } = column.columnDef.meta ?? {}

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  if (filterVariant === "date") {
    const values = columnFilterValue as [Date, Date];
    const startDate = values?.[0];
    const endDate = values?.[1];
    return (
      <>
        <button onClick={() => setIsDateModalOpen(true)} className="ml-auto">
          <FaFilter />
        </button>
        <Modal isOpen={isDateModalOpen} onRequestClose={() => setIsDateModalOpen(false)}
          style={{
            content: {
              width: 'fit-content',
              height: 'fit-content',
              margin: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}>
          <div>
            <div className="flex space-x-2">
              <DebouncedInput
                type="date"
                debounce={200}
                value={startDate ? dayjs(startDate).format('YYYY-MM-DD') : ''}
                className="border shadow rounded"
                onChange={(value) => {
                  if (
                    isValidDate(value) &&
                    value !== '' &&
                    value !== 'Invalid Date'
                  ) {
                    column.setFilterValue((old: [Date, Date]) => [
                      new Date(value),
                      old?.[1],
                    ]);
                  } else {
                    // Clear the filter value when the date is not valid
                    column.setFilterValue((old: [Date, Date]) => {
                      if (old?.[1]) {
                        return [null, old?.[1]];
                      }
                      return null;
                    });
                  }
                }}
              />
              <DebouncedInput
                type="date"
                debounce={200}
                value={endDate ? dayjs(endDate).format('YYYY-MM-DD') : ''}
                className="border shadow rounded"
                onChange={(value) => {
                  if (
                    isValidDate(value) &&
                    value !== '' &&
                    value !== 'Invalid Date'
                  ) {
                    column.setFilterValue((old: [Date, Date]) => [
                      old?.[0],
                      new Date(value),
                    ]);
                  } else {
                    // Clear the filter value when the date is not valid
                    column.setFilterValue((old: [Date, Date]) => {
                      if (old?.[0]) {
                        return [old?.[0], null];
                      }
                      return null;
                    });
                  }
                }}
              />
            </div>
            <div className="h-1" />
          </div>
        </Modal>
      </>
    );
  }

  return filterVariant === 'range' ? (
    <>
      <button onClick={() => setIsDateModalOpen(true)}>
        <FaFilter />
      </button>
      <Modal isOpen={isDateModalOpen} onRequestClose={() => setIsDateModalOpen(false)}
        style={{
          content: {
            width: 'fit-content',
            height: 'fit-content',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}>
        <div>
          <div className="flex space-x-2">
            <DebouncedInput
              type="number"
              min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
              max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
              value={(columnFilterValue as [number, number])?.[0] ?? ''}
              onChange={(value) => {
                column.setFilterValue((old: [number, number]) => [value, old?.[1]])
              }
              }
              placeholder={`Min ${true 
                  ? `(${column.getFacetedMinMaxValues()?.[0]})`
                  : ''
                }`}
              className="w-24 border shadow rounded"
            />
            <DebouncedInput
              type="number"
              min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
              max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
              value={(columnFilterValue as [number, number])?.[1] ?? ''}
              onChange={(value) =>
                column.setFilterValue((old: [number, number]) => [old?.[0], value])
              }
              placeholder={`Max ${column.getFacetedMinMaxValues()?.[1]
                  ? `(${column.getFacetedMinMaxValues()?.[1]})`
                  : ''
                }`}
              className="w-24 border shadow rounded"
            />
          </div>
          <div className="h-1" />
        </div>
      </Modal>
    </>
  ) : filterVariant === 'select' ? (
    <>
      <button onClick={() => setIsDateModalOpen(true)}>
        <FaFilter />
      </button>
      <Modal isOpen={isDateModalOpen} onRequestClose={() => setIsDateModalOpen(false)}
        style={{
          content: {
            width: 'fit-content',
            height: 'fit-content',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}>
        <datalist id={column.id + 'list'}>
          {sortedUniqueValues.slice(0, 5000).map((value: any) => (
            <option value={value} key={value} />
          ))}
        </datalist>
        <DebouncedInput
          type="text"
          value={(columnFilterValue ?? '') as string}
          onChange={(value) => column.setFilterValue(value)}
          placeholder={`${t("StatsPage.SearchBtn")}... (${column.getFacetedUniqueValues().size})`}
          className="w-36 border shadow rounded"
          list={column.id + 'list'}
        />
        <div className="h-1" />
      </Modal>
    </>
  ) : (
    <>
      <button onClick={() => setIsDateModalOpen(true)}>
        <FaFilter />
      </button>
      <Modal isOpen={isDateModalOpen} onRequestClose={() => setIsDateModalOpen(false)}
        style={{
          content: {
            width: 'fit-content',
            height: 'fit-content',
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}>
        <DebouncedInput
          type="text"
          value={(columnFilterValue ?? '') as string}
          onChange={(value) => column.setFilterValue(value)}
          placeholder={`${t("StatsPage.SearchBtn")}... (${column.getFacetedUniqueValues().size})`}
          className="w-36 border shadow rounded"
        />
      </Modal>
    </>
  );
}

function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}

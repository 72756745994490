import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

/* Hook allowing components to subscribe to the auth Context
for access to its values provided by the context Provider */

const useAuth = () => {
    return useContext(AuthContext);
}

export default useAuth;
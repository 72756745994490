import { Link, useSearchParams } from 'react-router-dom';
import { MdLogin } from 'react-icons/md'
import { useTranslation } from 'react-i18next';

/* Page loaded when user logs out or after idle period defined in RootLayout */

export function Logout() {

    const { t } = useTranslation();

    const [searchParams] = useSearchParams();
    const idle: string | null = searchParams.get("idle") // parameter query "idle" from URL

    return (
        <div className='mt-12'>
            <h2 className="text-center text-3xl font-medium tracking-tight text-gray-900">
                {t('Logout.ByeMessage')}
            </h2>
            <p className="mt-2 text-center text-base text-gray-600">
                {idle === "true"
                    ? t('Logout.SessionExpired')
                    : t('Logout.SessionDisconnected')}
            </p>
            <Link to="/login" className="mt-8 flex gap-1.5 items-center justify-center text-base text-center font-medium text-primary-400 hover:text-primary-500">
                <MdLogin className="w-3.5 h-3.5" />
                {t('Logout.BackToLogin')}
            </Link>
        </div>
    );
}
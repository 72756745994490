import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { FaPen, FaReply, FaTrash } from "react-icons/fa"
import { DiscussionMessages, Message } from '../app/models'
import {
    MessageRequest,
    getDiscussion,
    deleteDiscussion,
    createMessage,
    modifyMessage,
    modifyDiscussion,
    deleteMessage,
    getImage,
} from '../app/api'
import useAuth from '../hooks/useAuth';
import useApi from '../hooks/useApi';
import { ErrorLayout } from "../layouts/ErrorLayout";
import { MessageContainer } from '../components/forum'
import WarningModal from "../components/modals/WarningModal";
import { CustomToast } from "../components/CustomToast";
import Spinner from '../components/Spinner'
import { Avatar } from "../components/Avatar";
import { AddMessage } from "../components/forum/AddMessage";
import { showToast } from '../utils/Toast';
import { get } from "http";
import { useTranslation } from 'react-i18next';

import Linkify from 'react-linkify';



export function DiscussionPage() {

    const { t } = useTranslation();

    const { id } = useParams() as { id: string }; // URL id

    const { auth } = useAuth(); // session informations (visible in the localstorage)
    
    const isAdmin: boolean = auth?.role === "admin" ? true : false;

    /*const getDiscussionApi = useApi(getDiscussion); // getDiscussion API call <states></states> */

    const [discussionData, setDiscussionData] = useState<DiscussionMessages | null>(null);

    const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

    const [isCurrentUser, setIsCurrentUser] = useState(false);

    const [inputContent, setInputContent] = useState(discussionData?.description[discussionData?.description.length - 1]) // editing textarea content
    const [isReplying, setReplying] = useState(false); // display or not the message response component
    const [isEditing, setEditing] = useState(false); // display or not the message editing textarea
    const [isDeleting, setDeleting] = useState(false); // open or close the message deletion modal
    const [image, setImage] = useState("")


    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const response = await getDiscussion(id);

        if(response.data.fk_user_id?.avatar_key==="custom"){
            const base64Img : any = await getImage(response.data.fk_user_id._id);
            setImage(base64Img.data.image);
        }

        setDiscussionData(response.data);
        setIsCurrentUser(auth?.user._id === response.data?.fk_user_id._id);
        setInputContent(response.data?.description[response.data?.description.length-1]);
    };

    const onAddMessage = async (newMessage: Message) => {
        const response = await createMessage(
            newMessage.level, 
            newMessage.text, 
            newMessage.parent_message_id, 
            newMessage.fk_discussions_id._id, 
            newMessage.fk_user_id._id
        );
        showToast(t('DiscussionPage.CreateMessageSuccess'), 'success');
        getData();

    }

    const addResponse = (content: string): void => {
        if (auth?.user && discussionData) {
            const newMessage: Message = {
                _id: "",
                level: 1,
                text: [content],
                createdAt: "",
                updatedAt: "",
                parent_message_id: null,
                fk_user_id: auth.user,
                modified: false,
                fk_discussions_id: discussionData,
                responses: [],
                deleted: false
            };
            onAddMessage(newMessage);
        }
        // scroll to the bottom of the page
        window.scrollTo(0.5, document.body.scrollHeight);
        setReplying(false);
    }

    const onEditMessage = async (editedMessage: Message) => {
        const response = await modifyMessage(editedMessage.text, editedMessage._id);
        showToast(t('DiscussionPage.ModifMessageSuccess'), 'success');
        getData();
    };

    const updateDiscussion = async () => {
        if(inputContent && discussionData?.description){
            discussionData?.description.push(inputContent);
            const response = await modifyDiscussion(discussionData?.description, id);
            showToast(t('DiscussionPage.ModifDiscSuccess'), 'success');
            getData();
            setEditing(false);
        }else{
            showToast(t('DiscussionPage.ModifDiscError'), 'error');
        }
       

    }

    const onDeleteDiscussion = async () => {
        const response = await deleteDiscussion(id);
        showToast(t('DiscussionPage.DeleteDiscSuccess'), 'success');
        getData()
        setIsDeletionModalOpen(false)
    };

    const onDeleteMessage = async (deletedMessage: Message) => {
        const response = await deleteMessage(deletedMessage._id);
        showToast(t('DiscussionPage.DeleteMessageSuccess'), 'success');
        getData()
    }


    // Error page if bad id
    if (!discussionData) {
        return <ErrorLayout />
    }

    return (
        <>
            {discussionData && (
                <>
                
                {/* Module name */}

                {/*<div className="hidden lg:block">DESKTOP</div>
                <div className="md:block lg:hidden">TABLET</div>*/}
                
                <section className="mb-12 sm:mb-18 md:mb-20">
                    <div className='p-6 border-2 bg-white border-secondaryRed-500 rounded-lg shadow-lg'>
                    <div className="flex space-x-4 items-center mb-2 sm:mb-4">
                        <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">{t('DiscussionPage.ModuleTitle')}</h3>
                        <span className='h-8 w-px bg-secondaryRed-200'></span>
                        <p className="text-xl font-medium text-secondaryRed-700">{t('Modules.'+discussionData.fk_board_type_id.name+'.Type')}</p>
                    </div>
                    <p className="mb-1 font-medium text-gray-900">{t('DiscussionPage.ModuleDesc')}</p>
                    <p className='text-base text-gray-500'>{t('Modules.'+discussionData.fk_board_type_id.name+'.Description')}</p>
                    </div>
                </section> 
                
                {/* Message */}
                <div className="py-7 px-10 bg-white border border-b-4 border-primary-100/50  rounded-xl shadow-md mb-6">
                    <section className="">
                        {/* Message Header */}
                        <div className="flex flex-col space-x-0 space-y-3 md:flex-row md:space-y-0 md:space-x-14">
                            <div className="flex space-x-4 items-center w-full">
                                <div id="message-header" className="flex flex-col space-y-3 mb-6 md:flex-row md:space-y-0 md:mb-2 w-full">
                                    <div className="flex flex-col space-y-1 md:flex-row md:items-center md:gap-3 md:space-y-0">
                                        <div className="flex items-center gap-3">
                                            <div className="p-1 border-2 border-primary-100/50 rounded-full">
                                                {discussionData.fk_user_id?.avatar_key !== "custom" ? (
                                                    <Avatar avatarKey={discussionData.fk_user_id?.avatar_key} className="h-6 w-6" />
                                                ) : (
                                                    <img alt="preview profile picture" className="object-cover h-6 w-6 rounded-full" src={image}/>
                                                )}
                                            </div>
                                            <p className="font-bold text-base">{discussionData.fk_user_id?.username}</p>
                                        </div>
                                        <span className='h-8 w-0.5 bg-primary-100/50 hidden lg:block'></span>

                                        <h3 className={`text-xl font-medium sm:text-2xl 
                                            ${discussionData.deleted ? 'text-warningRose-500' : 'text-gray-900'}`} >
                                                {discussionData.deleted && !isAdmin ? "Discussion supprimée." : discussionData.title}
                                        </h3>
                                        
                                        <div className="flex gap-2 text-sm italic text-gray-400">
                                            <p>{t('DiscussionPage.Created')} {new Date(discussionData.createdAt).toLocaleDateString("default")}:{new Date(discussionData.createdAt).toLocaleTimeString("default")}</p>
                                            {discussionData.createdAt < discussionData.updatedAt && (
                                                <p>|&nbsp;&nbsp;{t('DiscussionPage.Modified')} {new Date(discussionData.updatedAt).toLocaleDateString("default")}:{new Date(discussionData.updatedAt).toLocaleTimeString("default")}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div id="message-btn" className="flex items-center gap-4 md:ml-auto">

                                        {/* Action buttons on message */}
                                        {((!isCurrentUser || discussionData.isPersonal) && !discussionData.deleted) && (
                                            <button
                                                type="button"
                                                onClick={() => setReplying(true)}
                                                className="flex gap-1.5 items-center text-sm font-bold text-primary-400 hover:text-primary-300 lg:text-base ml-auto"
                                            >
                                                <FaReply /> {t('DiscussionPage.Answer')}
                                            </button>
                                        )}
                                        {(isCurrentUser && !discussionData.deleted) && (
                                            <button
                                                type="button"
                                                onClick={() => setEditing(true)}
                                                className="flex gap-1.5 items-center text-sm font-bold text-primary-400 hover:text-primary-300 lg:text-base"
                                            >
                                                <FaPen /> {t('DiscussionPage.Update')}
                                            </button>
                                        )}
                                        {((isAdmin || isCurrentUser) && !discussionData.deleted) && (
                                            <button
                                                type="button"
                                                onClick={() => setIsDeletionModalOpen(true)}
                                                className="flex gap-1.5 items-center text-sm font-bold text-warningRose-500 hover:text-warningRose-400 lg:text-base"
                                            >
                                                <FaTrash /> {t('DiscussionPage.Delete')}
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Display description */}
                        <div id="message-body" className="flex flex-col gap-2 w-full ">
                            

                            {/* Message Content */}
                            {isEditing ? (
                                /* Edit message content */
                                <div id="message-edit-content">
                                    <textarea
                                        className="w-full h-44 p-2 border-2 rounded-md border-primary-100/50 resize-none focus:outline-none"
                                        value={inputContent}
                                        onChange={(e) => {
                                            setInputContent(e.target.value);
                                        }}
                                    />
                                    <div className="flex flex-row justify-end gap-4">
                                        <button
                                            type="button"
                                            className="mt-3 px-4 py-2 bg-neutral-200 rounded-md text-gray-700 font-medium hover:bg-neutral-300"
                                            onClick={() => setEditing(false)}
                                        >
                                            {t('DiscussionPage.Cancel')}
                                        </button>
                                        <button
                                            type="button"
                                            className="mt-3 px-4 py-2 bg-primary-500 rounded-md text-gray-100 font-medium hover:bg-primary-600"
                                            onClick={updateDiscussion}
                                        >
                                            {t('DiscussionPage.Save')}
                                        </button>
                                    </div>
                                </div>
                            ) :
                                /* Display message content */
                                <div id="message-content" className="text-base text-gray-500">
                                    <div className={`whitespace-pre-wrap 
                                        ${discussionData.deleted ? 'text-warningRose-500' : 'text-gray-500'}`}>
                                            {discussionData.deleted && !isAdmin ? "Ce message a été supprimé." : <Linkify>{discussionData.description[discussionData.description.length-1]}</Linkify> }
                                    </div>
                                </div>
                            }
                        </div>
                    </section>
                    </div>

                    {/* Add response */}
                    {isReplying && (
                        <div id="reply" className="ml-8 mb-6 pl-8 border-l-2 border-l-primary-100/50">
                            <AddMessage
                                onPublish={addResponse}
                                onCancel={() => setReplying(false)} />
                        </div>
                    )}

                    <section>
                        <div id="forum" className='w-[100%] mx-auto lg:ml-8 lg:pl-8 lg:border-l-2 border-l-primary-100/50 md:ml-auto md:mr-0 '>
                            {discussionData.messages.map((item) => (
                                <MessageContainer key={item._id} messages={[item]} onAdd={onAddMessage} onEdit={onEditMessage} onDelete={onDeleteMessage} isPersonal={discussionData.isPersonal} parent={discussionData}/>
                            ))}
                        </div>
                    </section>

                

                </>
            )}

            {/* Loading spinner */}
            {!discussionData && (
                <Spinner />
            )}

            {/* Delete message */}
            <WarningModal
                isOpen={isDeletionModalOpen}
                content="Cette action est irréversible. Confirmez-vous la suppression définitive de cette discussion ?"
                cancel={() => { setIsDeletionModalOpen(false) }}
                cancelLabel={t('DiscussionPage.Cancel')}
                confirm={onDeleteDiscussion}
                confirmLabel={t('DiscussionPage.Confirm')} />
        </>
    )
}
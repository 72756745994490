import type { RouteObject } from 'react-router-dom';
import { PrivateContent } from './PrivateContent';
import { HomePage } from '../pages/HomePage';
import { DiscussionListPage } from '../pages/DiscussionListPage'
import { DiscussionPage } from '../pages/DiscussionPage'
import { ModulePage } from '../pages/ModulePage'
import { AccountPage } from '../pages/AccountPage'
import { StatsPage } from '../pages/StatsPage';

// routes accessible only to "user" and "admin" users
// child routes below will be returned after session verification via PrivateContent element
export const privateRoutes: RouteObject[] = [
  {
    element: <PrivateContent roles={["admin", "user"]} />, 
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: '/general-discussions',
        element: <DiscussionListPage />,
      },
      {
        path: '/me-and-my-mood',
        element: <DiscussionListPage />,
      },
      {
        path: '/discussions/:id',
        element: <DiscussionPage />,
      },
      {
        path: '/modules/:id',
        element: <ModulePage />,
      },
      {
        path: '/account',
        element: <AccountPage />,
      },
      {
        path: '/stats',
        element: <StatsPage />,
      }
    ],
  },
];
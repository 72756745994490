import client from "../configs/axiosConfigs"
import {
    Message,
    Discussion,
    Module,
    User,
    ModuleById,
    UserCreate,
    UserLogin,
    LoginResponse,
    DiscussionMessages,
    CustomImage,
    Stats
} from "./models"

import clientToken from "../app/apiTokenClient";


/* Specific types for API request body */

export type MessageRequest = {
    level: number;
    parent_message_id: string | null,
    fk_discussions_id: string,
    fk_user_id: string,
    text: string[],
    createdAt: string,
    modified: boolean
}

/* Specific error types for API requests */
export class UsernameAlreadyExists extends Error {
    constructor(message?: string) {
        super(message);
        this.name = "UsernameAlreadyExistsError";
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

export class EmailAlreadyExists extends Error {
    constructor(message?: string) {
        super(message);
        this.name = "EmailAlreadyExistsError";
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

export class UserLoginFailError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = "UserLoginFailError";
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

export class UserNotFoundError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = "UserNotFoundError";
        Object.setPrototypeOf(this, new.target.prototype);
    }
}


/* Axios API requests */


const populateDB = async () => {
    return client.get("/rest/database/seed");
};

const getModules = async () => {
    const response = await clientToken.get('/rest/board_type/board_types');
    return response;
};

const getModule = (id: string, subgroupId: number, country: string, isGeneral: boolean) => {
    return clientToken.get<ModuleById>(`/rest/board_type/${id}/${subgroupId}/${country}/${isGeneral}`);
};

const getDiscussion = (id: string) => {
    return clientToken.get<DiscussionMessages>(`/rest/discussion/${id}`);
};

const createDiscussion = (title: string, description: string, fk_board_type_id: string, fk_user_id: string, isPersonal: boolean, fk_subgroup_id: number) => {
    return clientToken.post<Discussion>("/rest/discussion/discussion", JSON.stringify({title, description, 
        fk_board_type_id, fk_user_id, isPersonal, fk_subgroup_id}));
};

const modifyDiscussion = (newDescription: string[], idDiscussion: string) => {
    return clientToken.put<Discussion>("/rest/discussion/discussion", JSON.stringify({newDescription, idDiscussion}));
};


const deleteDiscussion = (id: string) => {
    return clientToken.delete(`/rest/discussion/${id}`);
};

const createMessage = (level: number, 
    text: string[], 
    parent_message_id: string | null, 
    fk_discussions_id: string, 
    fk_user_id: string) => {
    return clientToken.post<Message>("/rest/message/message", JSON.stringify({level, text, parent_message_id, fk_discussions_id, fk_user_id}));
};

const modifyMessage = (newText: string[], idMessage: string) => {
    return clientToken.put<Message>("/rest/message/message", JSON.stringify({newText, idMessage}));
};

const deleteMessage = (id: string) => {
    return clientToken.delete(`/rest/message/${id}`);
}

const getCountries = async () => {
    const response = await client.get('/rest/countries/countries');
    return response.data;
}

const createUser = async (data: UserCreate) => {
    try{
        const response = await client.post<UserCreate>("/rest/users/user", JSON.stringify(data));
        return response.data;
    } catch (error : any) {
        if (error.response && error.response.status === 401) {
            if(error.response.data.message === "Username already exists") {
                throw new UsernameAlreadyExists("Username already exists");
            } else if(error.response.data.message === "Email already exists") {
                throw new EmailAlreadyExists("Email already exists");
            }
        } else {
            // If it's another error, you might want to re-throw it
            throw error;
        }
    }
};

const modifyUser = (data: User) => {
    return clientToken.put<User>("/rest/users/user", JSON.stringify(data));
};

const modifyPassword = (_id: string, currentPassword: string, newPassword: string) => {
    return clientToken.put<User>("/rest/users/password", JSON.stringify({_id, currentPassword, newPassword}));
};

const updateTimeSpent = (_id: string, timespent: number) => {
    return clientToken.put<User>("/rest/users/timespent", JSON.stringify({_id, timespent}));
};

const logUser = async (data: UserLogin) => {
    try{
        const response = await client.get<LoginResponse>(`/rest/authenticate/${data.username}/${data.password}`);
        return response.data;
    } catch (error : any) {
        if (error.response && error.response.status === 401) {
            throw new UserLoginFailError("User login failed");
        } else if (error.response && error.response.status === 404) {
            throw new UserNotFoundError("User not found");
        } else {
            // If it's another error, you might want to re-throw it
            throw error;
        }
    }
}

const getSubgroups = async () => {
    return client.get("/rest/subgroup/subgroups");
};

const getPersonalDiscussion = async (id: string) => {
    return clientToken.get(`/rest/board_type/personal/${id}`);
}

const getImage = async (userId: string) => {
    return clientToken.get(`/rest/image/${userId}`);
}

const uploadImage = (img : CustomImage) => {
    return client.post<CustomImage>("/rest/image/", JSON.stringify(img));
};

const updateImage = (img : CustomImage) => {
    return clientToken.put<CustomImage>("/rest/image/", JSON.stringify(img));
};

const forgotPassword = async (email: string) => {
    return client.post(`/rest/forgotpassword/reset`, JSON.stringify({email}));
}

const resetPassword = async (email: string, password: string, token: string) => {
    return client.put(`/rest/forgotpassword/reset`, JSON.stringify({email, password, token}));
}

const getStats = (country: string) => {
    return clientToken.get<Stats>("/rest/stats/all", {params: {country}});
};


export {
    getModules,
    getModule,
    getDiscussion,
    createDiscussion,
    deleteDiscussion,
    createMessage,
    modifyMessage,
    createUser,
    modifyUser,
    logUser,
    populateDB,
    getCountries,
    modifyPassword,
    modifyDiscussion,
    deleteMessage,
    getSubgroups,
    getPersonalDiscussion,
    getImage,
    uploadImage,
    updateImage,
    forgotPassword,
    resetPassword,
    updateTimeSpent,
    getStats
}
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuth from "../hooks/useAuth";
import { ErrorLayout } from '../layouts/ErrorLayout';

export function PrivateContent({
    roles,
    children = <Outlet />,
}: {
    roles?: string[];
    children?: JSX.Element;
}) {

    const { auth } = useAuth();
    const location = useLocation();

    // If there's no active session, go /login
    if (!auth) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // check role of current user (active session)
    if (roles && !roles.includes(auth?.role)) {
        return <ErrorLayout />; // unauthorized
    }

    // return index child route defined in PrivatesRoutes
    return children;
}


import { createContext, useState } from "react";
import { Session } from "../app/models"
import { getLocalData, setLocalData, deleteLocalData} from "../app/localStorage"

/* Session context and provider */

const SESSION_KEY = 'auth-session'; // key used to store session info in localstorage

interface AuthContextType {
    auth: Session | undefined;
    setAuth: (auth: Session) => void;
    deleteAuth: () => void;
}

// Define a context its values { auth, setAuth, deleteAuth }
const AuthContext = createContext<AuthContextType>({
    auth: undefined,
    setAuth: () => { },
    deleteAuth: () => { }
});

// Returns a provider component that makes the auth context accessible
// to all its child components (children)
export const AuthProvider = ({ children }: any) => {
    const [auth, setSession] = useState<Session | undefined>(getLocalData(SESSION_KEY));

    const setAuth = (auth: Session) => {
        setSession(auth);
        setLocalData(SESSION_KEY, auth);
    };

    const deleteAuth = () => {
        setSession(undefined);
        deleteLocalData(SESSION_KEY);
    };

    return (
        <AuthContext.Provider value={{auth, setAuth, deleteAuth}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
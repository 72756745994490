import { useState, useEffect } from "react";
import clsx from 'clsx';
import { FaReply, FaTrash, FaPen } from "react-icons/fa"
import { Discussion, Message } from "../../app/models"
import useAuth from '../../hooks/useAuth';
import { MessageContainer, ActionOnMessageProps } from '.'
import { AddMessage } from "./AddMessage";
import WarningModal from "../modals/WarningModal";
import { Avatar } from "../Avatar";
import { getImage } from "../../app/api";
import { useTranslation } from 'react-i18next';

import Linkify from 'react-linkify';


export type PostedMessageProps = {
    message: Message;
    isPersonal: boolean;
    parent: Discussion | Message;
} & ActionOnMessageProps;


export function PostedMessage({
    message, // received from MessageContainer
    onAdd, // defined in DiscussionPage
    onEdit, // defined in DiscussionPage
    onDelete,
    isPersonal,
    parent
}: PostedMessageProps) {

    const { t } = useTranslation();

    const { auth } = useAuth(); // session informations (visible in the localstorage)

    const isCurrentUser: boolean = auth?.user._id === message.fk_user_id?._id ? true : false;
    const isAdmin: boolean = auth?.role === "admin" ? true : false;

    const [inputContent, setInputContent] = useState(message.text[message.text.length - 1]) // editing textarea content
    const [isReplying, setReplying] = useState(false); // display or not the message response component
    const [isEditing, setEditing] = useState(false); // display or not the message editing textarea
    const [isDeleting, setDeleting] = useState(false); // open or close the message deletion modal
    const [image, setImage] = useState("")

    const checkAvatar = async () => {
        if(message.fk_user_id?.avatar_key==="custom"){
            const base64Img : any = await getImage(message.fk_user_id._id);
            setImage(base64Img.data.image);
        }
    };

    useEffect(() => {
        checkAvatar();
    }, []);


    const addResponse = (content: string): void => {
        if (auth?.user) {
            const newMessage: Message = {
                _id: "",
                level: message.level + 1,
                text: [content],
                createdAt: "",
                updatedAt: "",
                parent_message_id: message._id,
                fk_user_id: auth.user,
                modified: false,
                fk_discussions_id: message.fk_discussions_id,
                responses: [],
                deleted: false
            };
            onAdd(newMessage);
        }
        setReplying(false);
    }

    const updateMessage = (): void => {
        let editedMessage: Message = { ...message };
        editedMessage.text.push(inputContent);
        onEdit(editedMessage);
        setEditing(false);
    }

    const onDeleteMessage = async () => {
        let deletedMessage: Message = { ...message };
        onDelete(deletedMessage);
        setDeleting(false);
    }

    const truncateText = (text: string): string => {
        return text.length > 100 ? `${text.substring(0, 100)}...` : text;
    };


    return (
        <>
            <div id="message" className="mb-6">
                <div className="py-7 px-10 bg-white border border-b-4 border-primary-100/50  rounded-xl shadow-md">
                    <div id="message-body" className="flex flex-col gap-2 w-full">

                        {/* Message Header */}
                        <div id="message-header" className="flex flex-col space-y-3 mb-6 md:flex-row md:space-y-0 md:mb-2">
                            <div className="flex flex-col space-y-1 md:flex-row md:items-center md:gap-3 md:space-y-0">
                                <div className="flex items-center gap-3">
                                    <div className="p-1 border-2 border-primary-100/50 rounded-full">
                                        {message.fk_user_id?.avatar_key !== "custom" ? (
                                            <Avatar avatarKey={message.fk_user_id?.avatar_key} className="h-6 w-6" />
                                        ) : (
                                            <img alt="preview profile picture" className="object-cover h-6 w-6 rounded-full" src={image}/>
                                        )}
                                    </div>
                                    <p className="font-bold text-base">{message.fk_user_id?.username}</p>
                                </div>
                                <div className="flex gap-2 text-sm italic text-gray-400">
                                    <p>{t('PostedMessage.Created')} {new Date(message.createdAt).toLocaleDateString("default")}:{new Date(message.createdAt).toLocaleTimeString("default")}</p>
                                    {message.createdAt < message.updatedAt && (
                                        <p>|&nbsp;&nbsp;{t('PostedMessage.Modified')} {new Date(message.updatedAt).toLocaleDateString("default")}:{new Date(message.updatedAt).toLocaleTimeString("default")}</p>
                                    )}
                                </div>
                            </div>
                            <div id="message-btn" className="flex items-center gap-4 md:ml-auto">

                                {(!isCurrentUser || isPersonal) && message.level < 2 && !message.deleted && (
                                    <button
                                        type="button"
                                        onClick={() => setReplying(true)}
                                        className="flex gap-1.5 items-center text-sm font-bold text-primary-400 hover:text-primary-300 lg:text-base"
                                    >
                                        <FaReply /> {t('PostedMessage.Answer')}
                                    </button>
                                )}
                                {(isCurrentUser && !message.deleted) && (
                                    <button
                                        type="button"
                                        onClick={() => setEditing(true)}
                                        className="flex gap-1.5 items-center text-sm font-bold text-primary-400 hover:text-primary-300 lg:text-base"
                                    >
                                        <FaPen /> {t('PostedMessage.Update')}
                                    </button>
                                )}
                                {((isAdmin || isCurrentUser) && !message.deleted) && (
                                    <button
                                        type="button"
                                        onClick={() => setDeleting(true)}
                                        className="flex gap-1.5 items-center text-sm font-bold text-warningRose-500 hover:text-warningRose-400 lg:text-base"
                                    >
                                        <FaTrash /> {t('PostedMessage.Delete')}
                                    </button>
                                )}
                            </div>
                        </div>

                        {/* Message Content */}
                        {isEditing ? (
                            /* Edit message content */
                            <div id="message-edit-content">
                                <textarea
                                    className="w-full h-44 p-2 border-2 rounded-md border-primary-100/50 resize-none focus:outline-none"
                                    value={inputContent}
                                    onChange={(e) => {
                                        setInputContent(e.target.value);
                                    }}
                                />
                                <div className="flex flex-row justify-end gap-4">
                                    <button
                                        type="button"
                                        className="mt-3 px-4 py-2 bg-neutral-200 rounded-md text-gray-700 font-medium hover:bg-neutral-300"
                                        onClick={() => setEditing(false)}
                                    >
                                        {t('PostedMessage.Cancel')}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 px-4 py-2 bg-primary-500 rounded-md text-gray-100 font-medium hover:bg-primary-600"
                                        onClick={updateMessage}
                                    >
                                        {t('PostedMessage.Save')}
                                    </button>
                                </div>
                            </div>
                        ) :
                            /* Display parent content and message */
                            <div>
                                <div id="message-parent" className="text-sm text-gray-500 border-l-2 border-l-primary-100/50 pl-4 mb-2 bg-primary-100 rounded-lg">
                                    {t('PostedMessage.ResponseTo')} {parent.fk_user_id?.username}
                                    <div className="text-gray-400">
                                        {(parent as Discussion).description !== undefined ? (
                                            <div>{truncateText((parent as Discussion).description[(parent as Discussion).description.length - 1])}</div>
                                        ) : (
                                            <div>{truncateText((parent as Message).text[(parent as Message).text.length - 1])}</div>
                                        )}
                                    </div>
                                </div>
                                
                                <div id="message-content" className="text-base text-gray-500">
                                    <div className={`whitespace-pre-wrap 
                                        ${message.deleted ? 'text-warningRose-500' : 'text-gray-500'}`}>
                                            {message.deleted && !isAdmin ? "Ce message a été supprimé." : <Linkify>{message.text[message.text.length - 1]}</Linkify>}
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {/* Add response */}
            {isReplying && (
                <div id="reply" className="ml-8 mb-6 pl-8 border-l-2 border-l-primary-100/50">
                    <AddMessage
                        onPublish={addResponse}
                        onCancel={() => setReplying(false)} />
                </div>
            )}

            {/* Responses container */}
            <div className={clsx(
                message.responses[0]?.level <= 2
                    ? 'lg:pl-8 lg:border-l-2 border-l-primary-100/50'
                    : '',
            )}>
                <MessageContainer messages={message.responses} onAdd={onAdd} onEdit={onEdit} onDelete={onDelete} isPersonal={isPersonal} parent={message} />
                {/*<section>
                    <div id="forum" className='w-[90%] mx-auto ml-8 pl-8 border-l-2 border-l-primary-100/50'>
                        {message.responses.map((item) => (
                            <MessageContainer key={item.id} messages={[item]} onAdd={onAdd} onEdit={onEdit} />
                        ))}
                    </div>
                </section>*/}
            </div >

            {/* Delete message */}
            < WarningModal
                isOpen={isDeleting}
                content={t('PostedMessage.DeleteTitle')}
                cancel={() => setDeleting(false)}
                cancelLabel={t('PostedMessage.Cancel')}
                confirm={onDeleteMessage}
                confirmLabel={t('PostedMessage.Confirm')} />
        </>
    )
}
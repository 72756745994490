
export function Footer() {
    return (
        <footer className="mx-auto mt-16 w-full max-w-container px-4 sm:mt-24 sm:px-6 md:mt-32 lg:px-8">
            <div className="border-t border-slate-900/5 py-12">
                <div className="flex flex-col items-center">
                    <span className="text-lg font-bold">Forum Indo-Suisse</span>
                    <p className="mt-2 text-base leading-6 text-primary-300">© 2023 Forum Indo-Suisse. All rights reserved.</p>
                </div>
                <div className="mx-auto max-w-3xl px-6 lg:px-8">
                    <div className="mx-auto mt-10 grid max-w-lg grid-cols-1 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-3 sm:gap-x-10 lg:mx-0 lg:max-w-none">
                        <img
                            className="max-h-12 w-full object-contain sm:col-span-1"
                            src="/logos/HumanTech.png"
                            alt="HumanTech"
                            width={158}
                            height={48}
                        />
                        <img
                            className="max-h-12 w-full object-contain sm:col-span-1"
                            src="/logos/HeiaFR.png"
                            alt="HeiaFR"
                            width={158}
                            height={48}
                        />
                        <img
                            className="max-h-12 w-full object-contain sm:col-span-1"
                            src="/logos/UniFR.png"
                            alt="UniFR"
                            width={158}
                            height={48}
                        />
                    </div>
                </div>
            </div>
        </footer>
    );
}
import { Link } from 'react-router-dom';
import { HiHome } from 'react-icons/hi';
import { Session } from '../app/models';
import useAuth from "../hooks/useAuth";
import { useTranslation } from 'react-i18next';

function getHomeLink(auth?: Session): string {
    if (!auth) {
        return '/login';
    } else {
        return '/';
    }
}


export function ErrorLayout() {

    const { t } = useTranslation();

    const { auth } = useAuth(); // session informations (visible in the localstorage)

    return (
        <div className='mt-12'>
            <h2 className="text-center text-3xl font-medium tracking-tight text-gray-900">
                {t('ErrorLayout.ErrorMessage')}
            </h2>
            <p className="mt-2 text-center text-base text-gray-600">
                {t('ErrorLayout.PageDontExist')}
            </p>
            <Link to={getHomeLink(auth)} className="mt-8 flex gap-1.5 items-center justify-center text-base text-center font-medium text-primary-400 hover:text-primary-500">
                <HiHome className="w-3.5 h-3.5" />
                {t('ErrorLayout.BackToHome')}
            </Link>
        </div>
    );
}
import axios from 'axios';

import client from "../configs/axiosConfigs"

let auth: any = null;

let setAuth: any = null;

export const setAuthApi = (newAuth: any, newSetAuth: any) => {
  auth = newAuth;
  setAuth = newSetAuth;
};

client.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = `Bearer ${auth?.accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

client.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      const res = await client.post('/rest/token/renewaccess', {
        refresh_token: auth?.refreshToken,
      });
      if (res.status === 200) {
        if(auth){
            console.log("Token successfully renewed");
            auth.accessToken = res.data.accessToken;
            setAuth({ 
              accessToken: res.data.accessToken,
              refreshToken: auth.refreshToken,
              role: auth.role,
              user: auth.user
            });
            return client(originalRequest);
        }
      }
    }
    return Promise.reject(error);
  }
);

const clientToken = client;

export default clientToken;
import { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import { Discussion, Module } from "../app/models";
import { getModules, getPersonalDiscussion, getModule } from '../app/api'
import useApi from '../hooks/useApi';
import { DiscussionCard, ModuleCard } from "../components/Card"
import Spinner from "../components/Spinner"
import useAuth from '../hooks/useAuth';
import { ErrorLayout } from "../layouts/ErrorLayout";
import { showToast } from "../utils/Toast";
import { useTranslation } from 'react-i18next';


export function HomePage() {

  const { t } = useTranslation();

  const { auth, setAuth } = useAuth();

  const [modules, setModules] = useState<Module[] | null>(null);

  const [meAndMyMood, setMeAndMyMood] = useState<Discussion | null>(null);

  const [discussionGeneral, setDiscussionGeneral] = useState<Discussion | null>(null);

  const fetchData = async () => {
    const userId : any = auth?.user._id;
    let subgroupId = auth?.user.fk_subgroup_id;
    let country = auth?.user.fk_country_id;
    if(auth?.role === 'admin'){
      subgroupId = 1;
    }
    if(!subgroupId || !country) {
      showToast(t('HomePage.ErrorDataUser'), 'error');
      return <ErrorLayout />;
    }
    const responseModule = await getModules();
    const personalDisc = await getPersonalDiscussion(userId);
    const generalDisc = await getModule("placeholder", subgroupId, country, true);
    setModules(responseModule.data);
    setMeAndMyMood(personalDisc.data.discussions[personalDisc.data.discussions.length - 1]);
    setDiscussionGeneral(generalDisc.data.discussions[generalDisc.data.discussions.length - 1]);
  };

  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array means this effect runs once on component mount

  if (!modules) return <Spinner />;

  
  return ( /* TODO: replace local constants with API data in rendering */
    <>  <h2 className="text-3xl text-gray-900 font-bold tracking-tight">{t('HomePage.Title')}</h2>

    <p className="mt-4">{t('HomePage.Desc1')}<span className="font-medium">Deprexis. </span>{t('HomePage.Desc2')}</p>

    <p className="mt-4">{t('HomePage.Desc3')}</p>

    <p className="mt-4">{t('HomePage.Desc4')}</p>
    <br></br>
    <br></br>
      <section className="mb-12 md:mb-16">
        <div className="flex flex-col gap-6 space-y-4 md:flex-row md:gap-20 md:space-y-0">
          {discussionGeneral && (
            <div className="w-[100%]">
              <div className="flex items-center mb-4 sm:mb-6">
                <h3 className="text-xl font-medium leading-6 text-gray-900 sm:text-2xl">
                {t('HomePage.GeneralDisc')}
                </h3>
                <Link to="/general-discussions" className="block w-fit ml-auto text-end text-neutral-400 font-medium leading-6 no-underline hover:underline hover:underline-offset-4 hover:decoration-neutral-400 sm:text-lg text-secondaryGreen-500">
                  {t('HomePage.AllDisc')}
                </Link>
              </div>
              <div>
                <DiscussionCard
                  key={discussionGeneral._id}
                  to={discussionGeneral._id}
                  title={discussionGeneral.title}
                  description={discussionGeneral.description[discussionGeneral.description.length - 1]}
                  isDeleted={discussionGeneral.deleted}
                  isAdmin={auth?.role === 'admin'}
                />
              </div>
            </div>
          )}
          {meAndMyMood && (
            <div className="w-[100%]">
              <div className="flex items-center mb-4 sm:mb-6">
                <h3 className="text-xl font-medium leading-6 text-gray-900 sm:text-2xl">
                  {t('HomePage.PersonalDisc')}
                </h3>
                <Link to="/me-and-my-mood" className="block w-fit ml-auto text-end text-neutral-400 font-medium leading-6 no-underline hover:underline hover:underline-offset-4 hover:decoration-neutral-400 sm:text-lg text-secondaryGreen-500">
                  {t('HomePage.AllDisc')}
                </Link>
              </div>
              <div>
                <DiscussionCard
                  key={meAndMyMood._id}
                  to={meAndMyMood._id}
                  title={meAndMyMood.title}
                  description={meAndMyMood.description[meAndMyMood.description.length - 1]}
                  isDeleted={meAndMyMood.deleted}
                  isAdmin={auth?.role === 'admin'}
                />
              </div>
            </div>
          )}
        </div>
      </section >

      {/* TODO: Uncomment and remove the "modulesExample" local constant when the board_type/board_types route is finalized. * /}
      {/* getModulesApi.data */ modules && (
        <section>
          <h3 className="mb-4 text-xl font-medium leading-6 text-gray-900 sm:text-2xl sm:mb-6">
            {t('HomePage.Modules')}
          </h3>
          <div className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
            {/* getModulesApi.data */ modules.map((module) => (
              <ModuleCard
                key={module.id}
                to={module.id}
                title={t('Modules.'+module.name+'.Type')}
                description={t('Modules.'+module.name+'.Description')}
                isDeleted={false}
                isAdmin={auth?.role === 'admin'}
              />
            ))}
          </div>
        </section>
      )}
    </>
  );
} 
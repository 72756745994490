import { useState } from "react";
import { PiUserCircle } from 'react-icons/pi'
import { useTranslation } from 'react-i18next';

/* Component used by ./PostedMessage */

export function AddMessage(props: {
    onPublish: (content: string) => void, // defined in PostedMessage
    onCancel: () => void // defined in PostedMessage
}) {

    const { t } = useTranslation();
    
    const [content, setContent] = useState("") // new message content

    return (
        <div id="add-message" className="py-7 px-10 bg-white border border-b-4 border-primary-100/50  rounded-xl shadow-md">
            <div id="add-message-body" className="flex flex-row gap-2 w-full">
                <PiUserCircle className="h-12 w-12 mr-2 rounded-full" />
                <textarea
                    className="w-full h-44 p-2 border-2 rounded-md border-primary-100/50 resize-none focus:outline-none"
                    value={content}
                    onChange={(e) => {
                        setContent(e.target.value);
                    }}
                />
                <div className="flex flex-col ml-4">
                    <button
                        className="block w-fit mr-auto mt-3 px-4 py-2 bg-primary-500 rounded-md text-gray-100 font-medium hover:bg-primary-600"
                        onClick={() => { props.onPublish(content) }}>
                        {t('AddMessage.Save')}
                    </button>
                    <button
                        className="block w-fit mr-auto mt-3 px-4 py-2 bg-neutral-200 rounded-md text-gray-700 font-medium hover:bg-neutral-300"
                        onClick={() => { props.onCancel() }}>
                        {t('AddMessage.Cancel')}
                    </button>
                </div>
            </div>
        </div>
    )
}